<template>
  <component
      :is="currentScreen"
      :action="action"
  />
</template>

<script>
import ForgotPasswordForm from "../screen/ForgotPasswordForm";
import PasswordResetForm from "../screen/PasswordResetForm";

export default {
  name: "PasswordResetActivity",
  inheritAttrs: false,
  components: {
    ForgotPasswordForm,
    PasswordResetForm,
  },
  props: ['action', 'startScreen'],
  data() {
    return {
      currentScreen: 'ForgotPasswordForm',
    }
  },
  methods: {},
  mounted() {
    if (this.startScreen) {
      this.currentScreen = this.startScreen;
    }
  },
}
</script>
