<template>
  <div class="side-panel-content bg-white p-5 px-md-10">
    <p class="font-h2 text-center my-6">
      {{ translate('front.user_panel.onboarding.subtitle') }}
    </p>
    <p class="text-center my-4 fs-16">{{ translate('front.user_panel.onboarding.message') }}</p>
    <div class="d-grid onboarding-buttons my-6">
      <a v-for="icon in icons"
          class="btn btn-grey4 d-flex flex-vertical justify-content-around onboarding-button my-2"
          :href="icon.url"
      >
        <span class="icon"><component :is="icon.icon" size="50px" /></span>
        {{ translate(icon.text) }}
      </a>
    </div>
  </div>
</template>

<script>
import BoatSearch from '../../vue/icons/BoatSearch'
import AdProfile from '../../vue/icons/AdProfile'
import BoatEuro from '../../vue/icons/BoatEuro'
import Community from '../../vue/icons/Community'
import Review from '../../vue/icons/Review'
import Chat from '../../vue/icons/Chat'
import Captain from '../../vue/icons/Captain'
import Profile from '../../vue/icons/Profile'
import translatable from '../../vue/mixins/translatable'

export default {
  name: "OnboardingScreen",
  components: {
    BoatSearch,
    AdProfile,
    BoatEuro,
    Community,
    Review,
    Chat,
    Captain,
    Profile,
  },
  mixins: [translatable],
  props: {
    userType: String,
  },
  data() {
    return {
      icons: [
        {
          url: window.urls.search,
          text: 'front.user_panel.onboarding.button.search',
          icon: 'BoatSearch',
        },
        {
          url: window.urls.adCreate,
          text: 'front.user_panel.onboarding.button.ad',
          icon: 'AdProfile',
        },
        {
          url: window.urls.quotation,
          text: 'front.user_panel.onboarding.button.quotation',
          icon: 'BoatEuro',
        },
        {
          url: window.urls.pros,
          text: 'front.user_panel.onboarding.button.pros',
          icon: 'Community',
        },
        {
          url: window.urls.review,
          text: 'front.user_panel.onboarding.button.review',
          icon: 'Review',
        },
        this.userType === 'pro' ? {
          url: window.urls.viewCompany,
          text: 'front.user_panel.onboarding.button.complete_company',
          icon: 'Captain',
        } : {
          url: window.urls.accountProfile,
          text: 'front.user_panel.onboarding.button.complete_profile',
          icon: 'Profile',
        },
        /*
        {
          url: window.urls.forum,
          text: 'front.user_panel.onboarding.button.forum',
          icon: 'Chat',
        },
        */
      ]
    };
  },
}
</script>
