<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip-annonce_profil)">
      <path
          d="M49.1231 39.6657C49.1231 40.4734 48.4213 41.1016 47.4564 41.1016H2.54411C1.66692 41.1016 0.877441 40.4734 0.877441 39.6657V9.33238C0.877441 8.52469 1.5792 7.89648 2.54411 7.89648H47.1932C48.2459 7.89648 49.1231 8.79392 49.1231 9.87084V39.6657Z"
          class="stroke-color1"
          :stroke="color1"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M5.87744 16.6016H18.4213"
          class="stroke-color1"
          :stroke="color1"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M5.87744 22.3457H23.6845"
          class="stroke-color1"
          :stroke="color1"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M5.87744 28.0879H24.5616"
          class="stroke-color1"
          :stroke="color1"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M5.87744 36.793H14.2108"
          class="stroke-color1"
          :stroke="color1"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M19.2108 36.793H29.1231"
          class="stroke-color1"
          :stroke="color1"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M44.7371 14.1797H31.5792V27.6412H44.7371V14.1797Z"
          class="stroke-color2"
          :stroke="color2"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip-annonce_profil">
        <rect width="50" height="35"
              class="fill-color3" :fill="color3"
              transform="translate(0.000244141 7)"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import svgIcon from '../mixins/svgIcon';

export default {
  name: "AdProfile",
  mixins: [svgIcon],
}
</script>