<template>
  <component :is="currentScreen"
    @changeScreen="onChangeScreen"
    :sso="sso"
    :ssoToken = "ssoToken"
    :ssoEmail="ssoEmail"
    :ssoLastname="ssoLastname"
    :ssoFirstname="ssoFirstname"
    :ssoCivility="ssoCivility"
    :data="data"
    :lockEmail="lockEmail"
    :redirectUrl="redirectUrl"
  />
</template>

<script>
import RegisterFormPart from "../screen/RegisterFormPart";
import RegisterFormPro from "../screen/RegisterFormPro";
import RegisterTypeSelectionScreen from "../screen/RegisterTypeSelectionScreen";
import eventBus from "../eventBus";

export default {
  name: "RegisterActivity",
  inheritAttrs: false,
  components: {
    RegisterFormPart,
    RegisterFormPro,
    RegisterTypeSelectionScreen,
  },
  props: [
    'sso',
    'ssoToken',
    'ssoEmail',
    'ssoLastname',
    'ssoFirstname',
    'ssoCivility',
    'redirectUrl',
    'startScreen',
    'userData',
  ],
  data() {
    return {
      currentScreen: 'RegisterTypeSelectionScreen',
      data: this.userData ?? {},
      lockEmail: this.userData?.lockEmail ?? false,
    }
  },
  methods: {
    onChangeScreen(event) {
      this.currentScreen = event.screen;
      if (event.data !== undefined){
        this.data = event.data;
      }
    },
  },
  mounted() {
    if (this.startScreen) {
      this.currentScreen = this.startScreen;
    }
    eventBus.emit('setCanCloseBackdrop', {canClose: false});
  },
  beforeUnmount() {
    eventBus.emit('setCanCloseBackdrop', {canClose: true});
  },
}
</script>
