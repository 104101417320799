<template>
  <a :href="logoutPath" class="logout-link fw-bold" @click="onLogout">
    <i class="bi bi-arrow-left"></i>
    <span>{{ translate('front.user_panel.email_confirmation.logout') }}</span>
    <i class="bi bi-power"></i>
  </a>
  <div class="side-panel-content p-10">
    <div>
      <p class="font-h2 text-center my-4">{{ translate('front.user_panel.sms_confirmation.title') }}</p>
      <p class="text-center text-black">{{ translate('front.user_panel.sms_confirmation.subtitle') }}</p>
    </div>

    <div>
      <form
          ref="form"
          @submit.prevent="onSubmit"
          class="needs-validation no-loader"
          :class="{'was-validated': formValidated}"
          novalidate
      >

        <div class="my-5 d-flex flex-horizontal">
          <PhoneInput
              name="phone"
              :required="true"
              :formValidated="formValidated"
              :label="translate('front.user_panel.sms_confirmation.phone_label')"
              :disabled="!changePhone"
              :error-messages="[translate('front.user_panel.sms_confirmation.invalid_phone_number')]"
              v-model="phone"
              v-model:country="phoneCountry"
          />
          <button v-if="changePhone" class="btn btn-white border-grey1 align-self-end ml-2" type="submit" id="submit-phone">
            <PaperPlane height="1.1em"/>
          </button>
        </div>
      </form>

      <div class="my-2" v-if="displayMessageCodeSent">
        <div class="alert alert-success">{{ translate('front.user_panel.sms_confirmation.code_sent_message') }} <a
            href="#" @click.prevent="hideMessageCodeSent">X</a></div>
      </div>

      <div class="d-flex flex-vertical align-items-center">
        <div class="form-group my-5 text-center" v-if="codeSent">
          <label for="input-code">{{ translate('front.user_panel.sms_confirmation.code_field_label') }}</label>
          <input
              type="text"
              name="code"
              id="input-code"
              class="form-control"
              required="required"
              maxlength="6"
              :disabled="!codeSent"
              style="width: 6em;"
              v-model="code"
          />
          <div class="invalid-feedback">{{ translate('front.user_panel.sms_confirmation.invalid_code') }}</div>
        </div>
      </div>
    </div>

    <div class="my-2" v-if="errorMessage">
      <div class="alert alert-danger">{{ errorMessage }}</div>
    </div>

    <div class="bg-skyblue p-5 px-md-10 text-black" v-if="codeSent">
      <p class="fw-bold text-center m-2">{{ translate('front.user_panel.sms_confirmation.sms_not_received.line1') }}</p>
      <p class="text-center m-2">{{ translate('front.user_panel.sms_confirmation.sms_not_received.line2') }}</p>
      <div class="d-flex flex-horizontal justify-content-between fw-bold m-2">
        <component :is="canSendCode ? 'a' : 'span'" :class="{'text-grey1': !canSendCode}" href="#"
                   @click.prevent="sendCode">{{ translate('front.user_panel.sms_confirmation.send_again') }} <span
            v-if="codeSentSecondsLeft !== null && codeSentSecondsLeft <= 60">({{ codeSentSecondsLeft }}s)</span>
        </component>
        <a href="#" @click.prevent="editPhone" class="d-inline-block ml-4">{{
            translate('front.user_panel.sms_confirmation.change_phone_number')
          }}</a>
      </div>
    </div>

    <div class="bg-white p-5 px-md-10 text-black fw-bold links-underline text-center">
      <a href="#" @click.prevent="useEmail">{{
          translate('front.user_panel.sms_confirmation.goto_email_confirmation')
        }}</a>
    </div>

  </div>
</template>

<script>
import Stepper from '../components/Stepper'
import PhoneInput from '../../vue/components/PhoneInput'
import PaperPlane from '../../vue/icons/PaperPlane'
import translatable from '../../vue/mixins/translatable';
import {deleteCookie} from "../../lib/cookies";
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "ConfirmPhoneForm",
  components: {PaperPlane, Stepper, PhoneInput},
  mixins: [translatable],
  props: ['userPhone', 'steps', 'previousStep', 'isPro'],
  data() {
    return {
      phone: this.userPhone,
      codeSent: false,
      codeSentDate: null,
      codeSentSecondsLeft: null,
      codeSentInterval: null,
      accountNotValidated: false,
      formValidated: false,
      canSendCode: true,
      changePhone: true,
      logoutPath: window.urls.logout,
      code: null,
      displayMessageCodeSent: false,
      errorMessage: null,
      phoneCountry: null,
    };
  },
  watch: {
    code(newValue, oldValue) {
      if (newValue.length == 6) {
        fetch(window.urls.sendConfirmationPhoneVerifCode, {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: newValue
        }).then((response) => {
          response.json().then((result) => {
            if (Math.floor(response.status / 100) === 2) {
              this.$emit('validationSuccess');
            } else {
              this.errorMessage = result.message;
            }
          });
        });
      }
    },
  },
  methods: {
    sendCode() {
      if (!this.canSendCode) {
        return;
      }
      this.canSendCode = false;
      this.errorMessage = null;

      fetch(window.urls.sendConfirmationPhone, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: this.phone,
          phoneCountry: this.phoneCountry,
        })
      }).then((response) => {
        if (Math.floor(response.status / 100) === 2) {
          this.changePhone = false;
          this.codeSent = true;
          this.codeSentDate = Date.now();
          this.codeSentSecondsLeft = 60;

          if (this.codeSentInterval) {
            clearInterval(this.codeSentInterval);
          }

          this.codeSentInterval = setInterval(() => {
            if (this.codeSentDate !== null) {
              this.codeSentSecondsLeft = 60 - Math.floor((Date.now() - this.codeSentDate) / 1000);
            }
            if (this.codeSentDate === null || this.codeSentSecondsLeft <= 0) {
              this.codeSentDate = null;
              this.codeSentSecondsLeft = null;
              this.canSendCode = true;
              clearInterval(this.codeSentInterval);
              this.codeSentInterval = null;
            }
          }, 200);
        } else {
          response.json().then((result) => {
            this.errorMessage = result.message;
          });
        }
      });
    },
    editPhone() {
      this.changePhone = true;
    },
    hideMessageCodeSent() {
      this.displayMessageCodeSent = false;
    },
    hideErrorMessage() {
      this.errorMessage = null;
    },
    onSubmit() {
      this.formValidated = true;

      if (!this.$refs.form.checkValidity()) {
        return;
      }
      this.canSendCode = true;

      this.sendCode();
    },
    useEmail() {
      this.$emit('changeScreen', {
        screen: 'ConfirmEmailForm',
      });
    },
    onLogout() {
      datalayerPush('click-login-logout', {
        userType: this.isPro ? 'pro' : 'individual',
      });
      deleteCookie('loginPanel');
    },
  },
}
</script>
