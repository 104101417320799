<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M29 1.7998V32.5998H48.3C46.3 21.0998 40 10.4998 30.4 2.7998L29 1.7998Z" stroke="#E73F4A"
            stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.6001 32.5996H25.9001" stroke="#E73F4A" stroke-width="2" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M25.1 8.19971L19.5 25.8997" stroke="#E73F4A" stroke-width="2" stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.8998 36.5996H48.2998L41.8998 42.0996H22.2998" stroke="#1A365F" stroke-width="2"
            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.9003 35.3C24.9003 29.6 20.3003 25 14.6003 25C8.90029 25 4.30029 29.6 4.30029 35.3C4.30029 41 8.90029 45.6 14.6003 45.6C20.3003 45.6 24.9003 41 24.9003 35.3Z"
            stroke="#1A365F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.3002 42.6001L1.7002 48.2001" stroke="#1A365F" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "BoatSearch",
  mixins: [svgIcon],
}
</script>