if (!window.Infoconnect) {
  window.Infoconnect = {
    initialized: false,
    keycloakInitialized: false,
    keycloak: null,
    initialization: null,
    keycloakInit: null,

    initialize() {
      if (!this.initialization) {
        this.initialization = new Promise((resolve, reject) => {
          if (this.keycloak) {
            return resolve(this)
          }
          this.initialized = true
          const initKeycloak = () => {
            if (typeof Keycloak !== 'undefined') {
              this.keycloak = new Keycloak({
                url: window.infoconnectBaseUrl,
                realm: 'sipa',
                clientId: 'band-of-boats-app-vv',
              })
              window.keycloak = this.keycloak
              resolve(this)
            } else if (!document.getElementById('keycloak-infoconnect-js')) {
              let elem = document.createElement('script')
              elem.id = 'keycloak-infoconnect-js'
              elem.src =
                'https://cdn.infoconnect.fr/auth/js/keycloak-staging.min.js'
              elem.async = true
              elem.defer = true

              document.body.appendChild(elem)
              setTimeout(() => {
                initKeycloak()
              }, 100)
            } else {
              setTimeout(() => {
                initKeycloak()
              }, 100)
            }
          }

          initKeycloak()
        })
      }

      return this.initialization
    },

    init(options = {}) {
      if (!this.keycloakInit) {
        options = { onLoad: 'check-sso', ...options }
        this.keycloakInit = this.keycloak.init(options)
      }

      return this.keycloakInit
    },
  }
}

export default window.Infoconnect
