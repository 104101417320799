<template>
  <div class="stepper">
    <div class="d-flex flex-horizontal justify-content-between" v-if="type == 'all' || type == 'text'">
      <div class="step"><span v-if="previous"><i class="bi bi-arrow-left"></i> {{ previous }} ({{currentStep - 1}} / {{nbSteps}})</span></div>
      <div class="step"><span v-if="next">{{ next }} ({{currentStep + 1}} / {{nbSteps}}) <i class="bi bi-arrow-right"></i></span></div>
    </div>
    <div class="d-flex flex-horizontal" v-if="type == 'all' || type == 'progressbar'">
      <div
          v-for="n in nbSteps"
          class="step-bar flex-grow-1"
          style="height: 0.5em;"
          :style="{
            'background-color': n < currentStep -1 ? '#093463' : (n == currentStep - 1 ? '#83c5ff' : '#eeeef2')
          }"
      >
        <div
            style="height: 100%; width: 100%"
            class="rounded-right"
            :class="{
              //'rounded-left': n !== 1 && n == currentStep,
              //'rounded-right': n !== nbSteps && n == currentStep,
            }"
            :style="{
              'background-color': n < currentStep ? '#093463' : (n == currentStep ? '#83c5ff' : '#eeeef2')
            }"
        ><div class="dot"></div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    nbSteps: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    previous: {
      type: String,
      default: null,
    },
    next: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'all',
    },
  }
}
</script>