import { createApp } from 'vue'
import App from './App.vue'
import eventBus from './eventBus'
import Infoconnect from './service/infoconnect'

import { deleteCookie, getCookie, setCookie } from '../lib/cookies'
import { DOMReady } from '../lib/domready'
import { datalayerPush } from '../lib/datalayer'
import Loader from '../vue/components/Loader'

function handleMustLogin(parentElement) {
  parentElement
    .querySelectorAll('a[data-must-login=true]')
    .forEach((element) => {
      if (element.dataset.loginEventSet) {
        return
      }
      element.dataset.loginEventSet = 'true'

      element.addEventListener('click', (event) => {
        const handleClick = (event) => {
          event.preventDefault()
          event.stopPropagation()

          eventBus.emit('changeActivity', {
            activity: 'HomeActivity',
            open: true,
            redirectUrl: element.dataset.link ?? element.href,
            dataLayer:
              element.dataset.datalayer !== undefined
                ? JSON.parse(element.dataset.datalayer)
                : null,
            data: {
              loginMessage: element.dataset.loginMessage,
            },
          })
        }

        if (element.dataset.datalayerEvent) {
          event.stopPropagation()
          event.preventDefault()

          const data = JSON.parse(element.dataset.datalayerData)
          if (!data.user_type && document.body.dataset.userType) {
            data.user_type = document.body.dataset.userType
          }
          datalayerPush(element.dataset.datalayerEvent, data, () => {
            handleClick(event)
          })
        } else {
          handleClick(event)
        }
      })
    })

  document.querySelectorAll('form[data-must-login=true]').forEach((element) => {
    element.addEventListener('submit', (event) => {
      event.preventDefault()
      event.stopPropagation()

      eventBus.emit('changeActivity', {
        activity: 'HomeActivity',
        open: true,
        successCallback: () => {
          event.target.submit()
        },
        redirectUrl: document.location.toString(),
        data: {
          loginMessage: event.currentTarget.dataset.loginMessage ?? null,
        },
      })
    })
  })
}

document.addEventListener('login-gated-content-loaded', (event) => {
  handleMustLogin(event?.target ?? document)
})
let loginPanelInitialized = false

DOMReady((event) => {
  if (loginPanelInitialized) {
    return
  }
  loginPanelInitialized = true
  let elem = document.createElement('div')
  elem.id = 'login-panel'
  document.getElementsByTagName('body')[0].appendChild(elem)
  let props = {}

  const loginPanel = createApp(App, { userData: window.userData ?? null })
  loginPanel.mount('#login-panel')

  const searchSelector = '[data-id=header-menu-user-search-alerts-list]'
  const priceSelector = '[data-id=header-menu-user-price-alerts-list]'
  const wishSelector = '[data-id=header-menu-user-ads-wishlist]'
  const searches = document.querySelectorAll(searchSelector)
  const prices = document.querySelectorAll(priceSelector)
  const wishes = document.querySelectorAll(wishSelector)
  if (searches.length && prices.length && wishes.length) {
    const containerMap = {
      search: searches,
      price: prices,
      wish: wishes,
    }
    document.addEventListener('updateUserListCount', (evt) => {
      if (!(evt.detail.container in containerMap)) {
        return
      }

      const containers = containerMap[evt.detail.container]
      for (const container of containers) {
        const badge = container.parentElement.querySelector('.badge')
        if (!badge) {
          return
        }

        switch (evt.detail.action) {
          case 'increment':
            badge.innerText = Number(badge.innerText) + 1
            break
          case 'decrement':
            const next = Number(badge.innerText) - 1
            if (next <= 0) {
              badge.innerText = ''
            } else {
              badge.innerText = next
            }
            break
        }
      }
    })
  }

  // Event requiring authentication
  document.addEventListener('must-login', (event) => {
    eventBus.emit('changeActivity', {
      activity: 'HomeActivity',
      open: true,
      redirectUrl: event.detail?.redirectUrl ?? null,
      successCallback: event.detail?.successCallback ?? null,
      successCallbackOnRegister:
        event.detail?.successCallbackOnRegister ?? null,
      data: {
        loginMessage: event.detail?.message,
      },
    })
  })

  // Links to the registration page (part)
  document
    .querySelectorAll(
      `a[href$=${window.urls.pageRegisterIndividual.replaceAll('/', '\\/')}]`
    )
    .forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()

        fetch(window.urls.accountStatus, {
          headers: { Accept: 'application/json' },
        }).then((response) => {
          response.json().then((result) => {
            if (result.isLoggedIn) {
              window.location.replace(event.target.href)
            } else {
              eventBus.emit('changeActivity', {
                activity: 'RegisterActivity',
                screen: 'RegisterFormPart',
                open: true,
                redirectUrl: event.target.href,
              })
            }
          })
        })
      })
    })

  // Links to the registration page (pro)
  document
    .querySelectorAll(
      `a[href$=${window.urls.pageRegisterPro.replaceAll('/', '\\/')}]`
    )
    .forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()

        fetch(window.urls.accountStatus, {
          headers: { Accept: 'application/json' },
        }).then((response) => {
          response.json().then((result) => {
            if (result.isLoggedIn) {
              window.location.replace(event.target.href)
            } else {
              eventBus.emit('changeActivity', {
                activity: 'RegisterActivity',
                screen: 'RegisterFormPro',
                open: true,
                redirectUrl: event.target.href,
              })
            }
          })
        })
      })
    })

  // Links to the profile page
  document
    .querySelectorAll(
      `a[href$=${window.urls.accountProfile.replaceAll('/', '\\/')}]`
    )
    .forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()

        fetch(window.urls.accountStatus, {
          headers: { Accept: 'application/json' },
        }).then((response) => {
          response.json().then((result) => {
            if (result.isLoggedIn) {
              window.location.replace(event.target.href)
            } else {
              eventBus.emit('changeActivity', {
                activity: 'HomeActivity',
                open: true,
                redirectUrl: event.target.href,
              })
            }
          })
        })
      })
    })

  // Cookie asking for authentication
  let loginPanelCookie = getCookie('loginPanel')
  if (loginPanelCookie !== undefined) {
    let openDefault = loginPanelCookie[1].split('-')
    if (openDefault[1] !== undefined) {
      props.startScreen = openDefault[1]
    }

    // Do not open the login panel if we're in the vv sso process
    if (
      getCookie('vv-sso') === undefined &&
      window.location.hash !== '#vv-sso'
    ) {
      eventBus.emit('changeActivity', {
        activity: openDefault[0] + 'Activity',
        screen: openDefault[1] ?? null,
        open: true,
      })
    }
    deleteCookie('loginPanel')
  }

  // Page loaded with a non-confirmed account
  fetch(window.urls.accountStatus, {
    headers: { Accept: 'application/json' },
  }).then((response) => {
    response.json().then((result) => {
      if (result.isLoggedIn && !result.accountValidated) {
        eventBus.emit('changeActivity', {
          activity: 'ConfirmActivity',
          open: true,
          data: {
            email: result.email,
            phone: result.phone,
            isPro: result.officeId !== null,
            isLead: result.isLead,
          },
        })
      }
    })
  })
})

DOMReady(
  (event) => {
    // Header menu
    // Login button
    if (document.getElementById('bob-menu-link-login-header')) {
      document
        .getElementById('bob-menu-link-login-header')
        .addEventListener('click', (e2) => {
          e2.preventDefault()
          e2.stopPropagation()
          eventBus.emit('changeActivity', {
            activity: 'HomeActivity',
            open: true,
          })
        })
    }
    // Profile menu (when logged in)
    if (document.getElementById('dropdownMenuProfile')) {
      document
        .getElementById('dropdownMenuProfile')
        .addEventListener('click', (e2) => {
          e2.preventDefault()
          e2.stopPropagation()
          eventBus.emit('changeActivity', {
            activity: 'MenuActivity',
            open: true,
          })
        })
    }

    handleMustLogin(event?.target ?? document)
  },
  ['HeaderActionsFragmentLoaded']
)

DOMReady(() => {
  if (window.location.hash) {
    const hashParams = new URLSearchParams(window.location.hash.substring(1))
    if (
      hashParams.has('code') &&
      hashParams.has('state') &&
      hashParams.has('session_state')
    ) {
      if (window.status_code !== undefined && window.status_code > 400) {
        showLoader()
      }
      Infoconnect.initialize().then((infoconnect) => {
        return infoconnect
          .init()
          .then((authenticated) => {
            if (authenticated) {
              return fetch(window.urls.ssoInfoconnect, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  token: infoconnect.keycloak.token,
                }),
              }).then((response) => {
                response.json().then((result) => {
                  let openRegister =
                    localStorage.getItem('infoconnect-open-register') === 'true'
                  localStorage.removeItem('infoconnect-open-register')
                  if (result.success) {
                    if (result.accountValidated) {
                      let vvSsoCookie = getCookie('vv-sso')
                      if (vvSsoCookie && vvSsoCookie[1] === 'true') {
                        deleteCookie('vv-sso')
                      }
                      // We hide the loader after a small time, in case the connectionSuccess event does not redirect the user
                      setTimeout(hideLoader, 1000)
                      eventBus.emit('connectionSuccess')
                    } else {
                      hideLoader()
                      eventBus.emit('changeActivity', {
                        activity: 'ConfirmActivity',
                        data: {
                          email: result.email,
                        },
                      })
                    }
                  } else {
                    if (result.mustRegister) {
                      if (openRegister) {
                        hideLoader()
                        infoconnect.keycloak.loadUserInfo().then((info) => {
                          eventBus.emit('changeActivity', {
                            activity: 'RegisterActivity',
                            open: true,
                            data: {
                              sso: 'infoconnect',
                              ssoToken: infoconnect.keycloak.token,
                              ssoEmail: info.email,
                            },
                            // redirectUrl: window.urls.adCreate,
                          })
                        })
                      } else if (
                        window.location.pathname !==
                        window.urls.lpVoilesVoiliers
                      ) {
                        const query = new URLSearchParams(
                          window.location.search
                        )
                        window.location.replace(
                          window.urls.lpVoilesVoiliers +
                            (query.has('uri')
                              ? `?uri=${query.get('uri')}`
                              : '') +
                            '#' +
                            hashParams.toString()
                        )
                      } else {
                        hideLoader()
                      }
                    } else {
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        event: 'error',
                        errorType: 'infoconnectSsoFailed',
                        errorField: 'credentials',
                        errorMessage: result?.error,
                      })
                      hideLoader()
                    }
                  }
                })
              })
            }
          })
          .then(() => {
            //document.getElementsByClassName('site-body')[0].style.opacity = 1
          })
      })
    } else if (hashParams.has('error')) {
      const query = new URLSearchParams(window.location.search)
      window.location.replace(
        window.urls.lpVoilesVoiliers +
          (query.has('uri') ? `?uri=${query.get('uri')}` : '')
      )
    } else if (window.location.hash === '#vv-sso') {
      if (
        document.getElementsByTagName('body')[0].dataset.userType !==
        'anonymous'
      ) {
        // user is already logged in, no need for SSO
        window.location.hash = ''
        return
      }
      if (window.status_code !== undefined && window.status_code > 400) {
        // Temporarily hide the content of the page, in order to avoid showing an error page before the auth process is complete
        showLoader()
      }

      setCookie('vv-sso', 'true')
      window.location.hash = ''
      Infoconnect.initialize().then((infoconnect) => {
        infoconnect.init({
          onLoad: 'login-required',
        })
      })
    }
  } else {
    if (
      document.getElementsByClassName('btn-register-infoconnect').length > 0
    ) {
      Infoconnect.initialize().then((infoconnect) => {
        infoconnect.init().then((authenticated) => {
          //console.log('LP INIT', authenticated)
        })
      })
    }
  }
})

DOMReady(() => {
  for (let element of document.getElementsByClassName(
    'btn-register-infoconnect'
  )) {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      event.stopPropagation()
      eventBus.emit('changeActivity', {
        activity: 'HomeActivity',
        open: true,
      })
    })
  }
})

document.addEventListener('user-logged-in', (event) => {
  deleteCookie('vv-sso')
})

let loaderApp = null
let loaderContainer = null

function showLoader() {
  if (!loaderApp) {
    loaderApp = createApp(Loader, {})
    loaderContainer = document.createElement('div')
    loaderContainer.style.background = 'white'
    loaderContainer.style.flexGrow = 1
    loaderContainer.style.minHeight = '70vh'
    document.getElementsByClassName('site-body')[0].after(loaderContainer)
    loaderApp.mount(loaderContainer)
  }
  document.getElementsByClassName('site-body')[0].classList.add('hidden')
  loaderContainer.classList.remove('hidden')

  return loaderApp
}

function hideLoader() {
  if (loaderApp) {
    loaderContainer.classList.add('hidden')
    document.getElementsByClassName('site-body')[0].classList.remove('hidden')
  }
}
