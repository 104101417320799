<template>
  <div class="login-panel-connect-buttons">
    <button
        data-test="login-panel-login-button"
        class="btn btn-white btn-outline-primary p-3 fw-bold w-100 d-flex flex-horizontal align-items-center justify-content-center"
        style="max-width:400px"
        @click.prevent="continueWithEmail">
      <i class="bi bi-envelope-open mx-2 fs-20" style="transform: translateY(-2px);"></i>
      {{ translate('front.user_panel.home.continue_with_email') }}
    </button>
    <FacebookSso />
    <GoogleSso />
    <InfoconnectSso v-if="showInfoconnectSso" />
  </div>
</template>

<script>
import GoogleSso from "./GoogleSso";
import FacebookSso from "./FacebookSso";
import InfoconnectSso from "./InfoconnectSso";
import eventBus from "../eventBus";
import translatable from "../../vue/mixins/translatable";

export default {
  name: "Sso",
  components: {
    GoogleSso,
    FacebookSso,
    InfoconnectSso,
  },
  mixins: [translatable],
  data() {
    return {
      showInfoconnectSso: window?.fflags['feature_flag.vv'] ?? false,
    }
  },
  methods: {
    continueWithEmail() {
      eventBus.emit('changeActivity', {
        activity: 'LoginActivity',
      });
    },
  },
}
</script>
