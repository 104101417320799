<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">

    <g>
      <path
          d="M31.5,29.8H14.7c-0.1,0-0.2,0-0.3,0.1l-5.7,4.6c-0.3,0.2-0.7,0-0.7-0.4v-3.8c0-0.2-0.2-0.5-0.5-0.5h-2 c-2.2,0-4-1.8-4-4V9.9c0-2,1.5-3.5,3.5-3.5H32c1.9,0,3.5,1.5,3.5,3.5v15.9C35.5,28.1,33.7,29.8,31.5,29.8z"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M14.5,35.8c0,2.2,1.8,4,4,4h16.8c0.1,0,0.2,0,0.3,0.1l5.7,4.6c0.3,0.2,0.7,0,0.7-0.4v-3.9 c0-0.2,0.2-0.5,0.5-0.5h2.1c2.2,0,4-1.8,4-4V20c0-1.9-1.5-3.5-3.5-3.5h-4.6"
          class="stroke-color2"
          :stroke="color2"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "Chat",
  mixins: [svgIcon],
}
</script>