<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">

    <g>
      <path
          d="M15.1,23c4.7,0,8.5-3.8,8.5-8.5c0-4.7-3.8-8.5-8.5-8.5c-4.7,0-8.5,3.8-8.5,8.5C6.6,19.2,10.4,23,15.1,23z"
          class="stroke-color2"
          :stroke="color2"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M2.6,37c0-7.2,5.6-13,12.5-13c7,0,12.5,5.9,12.5,13"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M36.3,27c-5.4,0-9.7,4.5-9.7,9.9c0,5.4,4.3,9.9,9.7,9.9s9.7-4.5,9.7-9.9c0-1.8-0.5-3.5-1.3-4.9
                            c-0.3-0.5-0.9-0.7-1.4-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-7.4,6.1l-3-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.7,3.7
                            c0.4,0.4,1,0.4,1.4,0.1l7.3-6.1c0.3,0.9,0.5,1.8,0.5,2.8c0,4.4-3.5,7.9-7.7,7.9s-7.7-3.5-7.7-7.9s3.5-7.9,7.7-7.9
                            c1.3,0,2.5,0.3,3.7,1c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.1-1.1-0.4-1.4C39.6,27.4,38,27,36.3,27z"
          class="fill-color2" :fill="color2"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "Profile",
  mixins: [svgIcon],
}
</script>
