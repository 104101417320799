<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">

    <g>
      <path
          d="M41.8,31c0,0,7-6.5,5.6-9.5c-1.4-3-12.7-11.6-21.9-11.6h-1.2c-9.2,0-20.5,8.7-21.9,11.7 c-1.4,3,5.6,9.5,5.6,9.5L41.8,31L41.8,31z"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M7.8,33.8c0,0,0,7.3,17.6,7.3h-0.7c17.6,0,17.6-7.3,17.6-7.3"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M25.3,13.2c-4,0-7.2,3.3-7.2,7.3s3.2,7.3,7.2,7.3c4,0,7.2-3.3,7.2-7.3c0-1.3-0.4-2.6-0.9-3.6 c-0.3-0.5-0.9-0.7-1.4-0.4c-0.2,0.1-0.3,0.2-0.4,0.3l-5,4.2l-2-2c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.6,2.6 c0.4,0.4,1,0.4,1.4,0.1l4.9-4.1c0.1,0.5,0.2,1,0.2,1.5c0,3-2.4,5.3-5.2,5.3s-5.2-2.4-5.2-5.3s2.4-5.3,5.2-5.3 c0.9,0,1.7,0.2,2.5,0.7c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.1-1.1-0.4-1.4C27.7,13.6,26.6,13.2,25.3,13.2z"
          class="fill-color2" :fill="color2"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "Captain",
  mixins: [svgIcon],
}
</script>
