<template>
  <div>
    <Tabs active="register"/>
    <div class="side-panel-content bg-white p-5 px-md-10">
      <div v-if="comesFromVv">
        <div class="d-flex flex-horizontal align-items-center justify-content-between my-4">
          <div style="width: 40%"><VoilesVoiliersLogo /></div>
          <div style="width: 50%"><LogoFull /></div>
        </div>
        <div class="my-4">
          <p>{{ translate('front.app_register.voiles-voilier.introtext') }}</p>
        </div>
      </div>

      <div class="text-center my-4">
        <p class="font-h2">{{ translate('front.app_register.registering.label') }}</p>
      </div>
      <div class="text-center my-4">
        <button @click="onIamPart" class="btn btn-outline-primary" style="width:200px" data-test="login-panel-individual-registration-button">{{ translate('front.app_register.button.part.label') }}</button>
      </div>
      <div class="text-center my-4">{{ translate('front.app_register.registering.or') }}</div>
      <div class="text-center my-4">
        <button @click="onIamPro" class="btn btn-outline-primary" style="width:200px" data-test="login-panel-professional-registration-button">{{ translate('front.app_register.button.pro.label') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import translatable from '../../vue/mixins/translatable'
import Tabs from "../components/Tabs";
import VoilesVoiliersLogo from "../../vue/images/VoilesVoiliersLogo";
import {getCookie} from '../../lib/cookies'
import LogoFull from "../../vue/images/LogoFull";

export default {
  name: "RegisterTypeSelectionScreen",
  components: {
    LogoFull,
    Tabs,
    VoilesVoiliersLogo,
  },
  mixins: [translatable],
  props: {
  },
  data() {
    let vvSsoCookie = getCookie('vv-sso');
    return {
      comesFromVv: vvSsoCookie?.length ? vvSsoCookie[1] === 'true' : false,
    };
  },
  methods: {
    onIamPro() {
      this.$emit('changeScreen', {
        screen: 'RegisterFormPro',
      });
    },
    onIamPart() {
      this.$emit('changeScreen', {
        screen: 'RegisterFormPart',
      });
    },
  },
}
</script>
