<template>
  <div class="side-panel-content side-panel-padding pt-10" v-if="show">
    <div class="d-flex justify-content-center">
      <div class="login-panel-logo">
        <Logo size="3em"/>
      </div>
    </div>
    <div class="login-panel-title font-h2 bg-white text-center fs-20 fw-bold">
      {{ message ? message : translate('front.user_panel.home.message') }}
    </div>
    <Sso/>
    <div class="mt-10">
      <div class="mb-3 d-flex flex-horizontal justify-content-center">
        <span class="mr-1">{{ translate('front.user_panel.home.already_have_account') }}</span>
        <a href="#" @click.prevent="login" class="link">{{ translate('front.user_panel.home.login') }}</a>
      </div>
      <div class="d-flex flex-horizontal justify-content-center">
        <span class="mr-1">{{ translate('front.user_panel.home.no_account') }}</span>
        <a href="#" @click.prevent="register" class="link">{{ translate('front.user_panel.home.register') }}</a>
      </div>
    </div>
  </div>
  <Loader v-else />
</template>

<script>
import Sso from '../components/Sso';
import Logo from "../../vue/icons/Logo";
import translatable from '../../vue/mixins/translatable';
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";
import Loader from "../../vue/components/Loader";
import {getCookie} from "../../lib/cookies";
import Infoconnect from "../service/infoconnect";

export default {
  name: "HomeScreen",
  mixins: [translatable],
  components: {Loader, Sso, Logo},
  props: {
    loginMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      message: this.loginMessage ? this.loginMessage : (window.requireAuthenticationMessage !== undefined ? window.requireAuthenticationMessage : null),
      show: false,
    };
  },
  methods: {
    login() {
      eventBus.emit('changeActivity', {
        activity: 'LoginActivity',
      });
    },
    register() {
      eventBus.emit('changeActivity', {
        activity: 'RegisterActivity',
      });
    },
    initializeInfoconnect() {
      return Infoconnect.initialize().then((infoconnect) => {
        datalayerPush('click-login-SSO', {
          method: 'infoconnect',
        });
        localStorage.setItem('infoconnect-open-register', 'true');
        return infoconnect.init({
          onLoad: 'login-required',
        }).then((authenticated) => {
          if (!authenticated) {
            infoconnect.keycloak.login();
          } else {
            return infoconnect.keycloak.loadUserInfo().then((info) => {
              'user info loaded';
              eventBus.emit('changeActivity', {
                activity: 'RegisterActivity',
                open: true,
                data: {
                  sso: 'infoconnect',
                  ssoToken: infoconnect.keycloak?.token,
                  ssoEmail: info.email,
                },
                redirectUrl: window.urls.adCreate,
              })
            })
          }
        });
      });
    },
  },
  mounted() {
    datalayerPush('vpv-login-loginMethod');

    let vvSsoCookie = getCookie('vv-sso');
    if (vvSsoCookie && vvSsoCookie[1] === 'true') {
      this.initializeInfoconnect().then(() => {
        this.show = true;
      })
    } else {
      this.show = true;
    }
  },
}
</script>
