<template>
  <div>
    <div class="side-panel-backdrop" :class="{closed: !opened}" @click.prevent="closeBackdrop"></div>
    <div class="side-panel" ref="panel" :class="{closed: !opened}">
      <div class="side-panel-header p-6 d-flex flex-horizontal justify-content-between align-items-center position-relative">
        <p class="font-h2 flex-grow-1 text-center">{{ translate(title) }}</p>
        <div class="position-absolute top-0 right-0 h-100 d-flex align-items-center pr-5">
          <a
              class="fs-22"
              href="#"
              v-if="canClose"
              @click.prevent="close"
          ><i class="bi bi-x-lg"></i></a>
        </div>
      </div>
      <component
          :is="currentActivity"
          v-if="currentActivity"
          v-bind="activityData"
          :userData="userData"
          :startScreen="startScreen"
          :redirectUrl="redirectUrl"
          @changeActivity="onChangeActivity"
          @setCanClose="onSetCanClose"
          @setTitle="onSetTitle"
      />
    </div>
  </div>
</template>

<script>
import ConfirmActivity from './activity/ConfirmActivity';
import HomeActivity from './activity/HomeActivity';
import LoginActivity from './activity/LoginActivity';
import MenuActivity from './activity/MenuActivity'
import OnboardingActivity from './activity/OnboardingActivity';
import PasswordResetActivity from './activity/PasswordResetActivity';
import RegisterActivity from './activity/RegisterActivity';
import translatable from "../vue/mixins/translatable";
import eventBus from "./eventBus";
import breakpoints from '../lib/breakpoints';

export default {
  components: {
    ConfirmActivity,
    HomeActivity,
    LoginActivity,
    MenuActivity,
    OnboardingActivity,
    PasswordResetActivity,
    RegisterActivity,
  },
  mixins: [translatable],
  props: ['userData'],
  data() {
    return {
      opened: false,
      currentActivity: null,
      activityData: {},
      canClose: true,
      canCloseBackdrop: true,
      redirectUrl: null,
      successCallback: null,
      successCallbackOnRegister: false,
      startScreen: null,
      title: 'front.user_panel.title',
      dataLayer: null,
    }
  },
  watch: {
    opened(newValue, oldValue) {
      if (newValue) {
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
      }
    },
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      if (this.canClose) {
        this.currentActivity = null;
        this.opened = false;
        if (this.currentActivity === 'OnboardingActivity') {
          window.location.reload();
        }
      }
    },
    closeBackdrop() {
      if (this.canCloseBackdrop) {
        this.close();
      }
    },
    changeActivity(activity, data = {}) {
      this.currentActivity = activity;
      this.activityData = data;
    },
    onChangeActivity(event) {
      if (this.$refs.panel) {
        this.$refs.panel.scrollTop = 0;
      }
      if (event.redirectUrl !== undefined) {
        this.redirectUrl = event.redirectUrl;
      }
      if (event.successCallback !== undefined) {
        this.successCallback = event.successCallback;
      }
      if (event.successCallback !== undefined) {
        this.successCallback = event.successCallback;
      }
      if (event.successCallbackOnRegister !== undefined) {
        this.successCallbackOnRegister = event.successCallbackOnRegister;
      }
      if (event.dataLayer !== undefined) {
        this.dataLayer = event.dataLayer;
      }
      if (event.screen) {
        this.startScreen = event.screen;
      }

      if (event.data !== undefined) {
        this.changeActivity(event.activity, event.data);
      } else {
        this.changeActivity(event.activity)
      }
      if (event.open) {
        this.open();
      }
      if (event.canClose !== undefined) {
        this.canClose = event.canClose;
      }
      setTimeout(() => breakpoints.init(), 1);
    },
    onConnectionSuccess(event) {
      this.triggerLoginEvent();
      if (event !== undefined && event.close !== false) {
        this.close();
      }

      if (this.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(this.dataLayer);
      }
      const query = new URLSearchParams(
          window.location.search
      )
      if (this.successCallback) {
        let callBackResult = this.successCallback();
        if (this.redirectUrl) {
          if (callBackResult instanceof Promise) {
            callBackResult.then(() => {
              window.location.assign(this.redirectUrl);
            });
          } else {
            window.location.assign(this.redirectUrl);
          }
        }
        this.close();
      } else if (this.redirectUrl) {
        window.location.assign(this.redirectUrl);
      } else if (query.has('uri')) {
        window.location.assign(query.get('uri'));
      } else if (event !== undefined && event.onboarding) {
        this.changeActivity('OnboardingActivity');
      } else {
        window.location.reload();
      }
    },
    onRegisterSuccess() {
      if (this.successCallbackOnRegister && this.successCallback) {
        this.successCallback();
        this.successCallback = null;
      }
    },
    onSetCanClose(event) {
      this.canClose = event.canClose;
    },
    onSetCanCloseBackdrop(event) {
      this.canCloseBackdrop = event.canClose;
    },
    onSetTitle(event) {
      this.title = event.title;
    },
    triggerLoginEvent() {
      let loginEvent = new CustomEvent('user-logged-in', {detail: {}});
      document.dispatchEvent(loginEvent);
    },
  },
  mounted() {
    eventBus.on('changeActivity', this.onChangeActivity);
    eventBus.on('connectionSuccess', this.onConnectionSuccess);
    eventBus.on('registerSuccess', this.onRegisterSuccess);
    eventBus.on('setCanCloseBackdrop', this.onSetCanCloseBackdrop);
  },
  unmounted() {
    eventBus.off('changeActivity', this.onChangeActivity);
    eventBus.off('connectionSuccess', this.onConnectionSuccess);
    eventBus.off('registerSuccess', this.onRegisterSuccess);
    eventBus.off('setCanCloseBackdrop', this.onSetCanCloseBackdrop);
  }
}
</script>