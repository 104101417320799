<template>
  <a :href="logoutPath" class="logout-link fw-bold" @click="onLogout">
    <i class="bi bi-arrow-left"></i>
    <span>{{ translate('front.user_panel.email_confirmation.logout') }}</span>
    <i class="bi bi-power"></i>
  </a>
  <div class="side-panel-content">
    <div class="p-10">
      <p class="font-h2 text-center my-4">{{ translate('front.user_panel.email_confirmation.title') }}</p>
      <p class="text-center text-black">{{ translate('front.user_panel.email_confirmation.message') }}</p>
      <div>
        <form
            ref="form"
            @submit.prevent="onSubmit"
            class="needs-validation d-flex flex-horizontal justify-content-center no-loader"
            :class="{'was-validated': formValidated}"
            novalidate
        >
          <div class="form-group my-5 d-flex align-items-center flex-vertical">
            <input type="email" name="email" id="input-email"
                   class="border-bottom border-2 border-grey1 p-2 text-center bg-white" required="required"
                   :disabled="!changeEmail" v-model="emailData"/>
            <div class="invalid-feedback" v-if="!remoteEmailErrorMessage">
              {{ translate('front.user_panel.email_confirmation.invalid_email') }}
            </div>
            <div class="invalid-feedback" v-else>{{ remoteEmailErrorMessage }}</div>
          </div>
          <div class="form-group m-2 w-100" v-if="changeEmail">
            <label for="submit-email">&nbsp;</label>
            <button type="submit" id="submit-email" class="form-control btn btn-white border-grey1">
              <PaperPlane height="1.1em"/>
            </button>
          </div>
        </form>
        <div class="my-2" v-if="emailSent">
          <div class="alert alert-success">{{
              translate('front.user_panel.email_confirmation.email_sent_message')
            }}
          </div>
        </div>
        <div class="my-2" v-if="emailNotValidated">
          <div class="alert alert-danger">{{
              translate('front.user_panel.email_confirmation.email_not_validated_message')
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="px-10 py-5 bg-skyblue">
      <p class="fw-bold text-center m-2">
        {{ translate('front.user_panel.email_confirmation.email_not_received_block.title') }}</p>
      <p class="text-center m-2">{{
          translate('front.user_panel.email_confirmation.email_not_received_block.subtitle')
        }}</p>
      <div class="d-flex flex-horizontal justify-content-between fw-bold mt-5 links-underline">
        <component :is="canSendEmail ? 'a' : 'span'" :class="{'text-grey1': !canSendEmail}" href="#"
                   @click.prevent="sendEmail">{{ translate('front.user_panel.email_confirmation.send_again') }} <span
            v-if="emailSentSecondsLeft !== null && emailSentSecondsLeft <= 60">({{ emailSentSecondsLeft }}s)</span>
        </component>
        <a v-if="!emailOnly"
          href="#"
          @click.prevent="editEmail"
          class="text-black"
        >{{ translate('front.user_panel.email_confirmation.change_email_button') }}</a>
      </div>
    </div>
    <div class="fw-bold links-underline text-center pt-5" v-if="!emailOnly">
      <a href="#" @click.prevent="useSms">{{
          translate('front.user_panel.email_confirmation.goto_sms_confirmation')
        }}</a>
    </div>

  </div>
  <div class="side-panel-footer">
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary" @click.prevent="verifyAccount">
        {{ translate('front.user_panel.email_confirmation.check_account') }}
      </button>
    </div>
  </div>
</template>

<script>
import PaperPlane from '../../vue/icons/PaperPlane'
import Stepper from '../components/Stepper'
import translatable from '../../vue/mixins/translatable'
import {deleteCookie} from "../../lib/cookies";
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "ConfirmEmailForm",
  components: {PaperPlane, Stepper},
  mixins: [translatable],
  data() {
    return {
      emailData: this.email,
      emailSent: false,
      emailSentDate: null,
      emailSentSecondsLeft: null,
      emailSentInterval: null,
      canSendEmail: true,
      emailNotValidated: false,
      formValidated: false,
      changeEmail: false,
      remoteEmailErrorMessage: null,
      logoutPath: window.urls.logout,
    };
  },
  props: [
    'email',
    'steps',
    'previousStep',
    'redirectUrl',
    'emailOnly',
  ],
  computed: {},
  methods: {
    sendEmail() {
      if (!this.canSendEmail) {
        return;
      }

      datalayerPush('click-login-sendEmail', {
        userType: this.isPro ? 'pro' : 'individual',
      });

      this.canSendEmail = false;

      let body = {};
      if (this.redirectUrl) {
        body.redirectUrl = this.redirectUrl;
      }
      fetch(window.urls.resendConfirmationEmail, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then((response) => {
        if (Math.floor(response.status / 100) === 2) {
          this.emailSent = true;
          this.emailSentDate = Date.now();
          this.emailSentSecondsLeft = 60;
          this.emailSentInterval = setInterval(() => {
            if (this.emailSentDate !== null) {
              this.emailSentSecondsLeft = 60 - Math.floor((Date.now() - this.emailSentDate) / 1000);
            }
            if (this.emailSentDate === null || this.emailSentSecondsLeft <= 0) {
              this.emailSentDate = null;
              this.emailSentSecondsLeft = null;
              this.canSendEmail = true;
              clearInterval(this.emailSentInterval);
              this.emailSentInterval = null;
            }
          });
        }
      });
    },
    verifyAccount(event, mute = false) {
      if (!mute) {
        datalayerPush('click-login-checkValidation', {
          userType: this.isPro ? 'pro' : 'individual',
        });
      }
      fetch(window.urls.accountStatus, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then((response) => {
        response.json().then((result) => {
          if (result.accountValidated) {
            this.emailNotValidated = false;
            this.$emit('validationSuccess');
          } else if (!mute) {
            this.emailNotValidated = true;
          }
        });
      });
    },
    editEmail() {
      if (this.emailOnly) {
        return;
      }
      datalayerPush('click-login-updateEmail', {
        userType: this.isPro ? 'pro' : 'individual',
      });
      this.changeEmail = true;
    },
    useSms() {
      if (this.emailOnly) {
        return;
      }

      datalayerPush('click-login-SMSValidation', {
        userType: this.isPro ? 'pro' : 'individual',
      });
      this.$emit('changeScreen', {
        screen: 'ConfirmPhoneForm',
      });
    },
    onSubmit() {
      this.formValidated = true;
      this.remoteEmailErrorMessage = null;
      if (!this.$refs.form.checkValidity()) {
        return;
      }

      let body = {
        newEmail: this.emailData,
      };
      if (this.redirectUrl) {
        body.redirectUrl = this.redirectUrl;
      }

      fetch(window.urls.resendConfirmationEmail, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then((response) => {
        response.json().then((result) => {
          if (result.success) {
            this.changeEmail = false;
            this.emailSent = true;
            this.formValidated = false;
          } else {
            this.remoteEmailErrorMessage = result.errors[0];
          }
        });
      });
    },
    onLogout() {
      datalayerPush('click-login-logout', {
        userType: this.isPro ? 'pro' : 'individual',
      });
      deleteCookie('loginPanel');
      deleteCookie('vv-sso');
    },
  },
  mounted() {
    this.verifyAccount({}, true);
  },
}
</script>
