<template>
  <div ref="menu" v-html="menuHtml"></div>
</template>

<script>
export default {
  name: "MenuScreen",
  data() {
    return {
      menuHtml: null,
    };
  },
  methods: {},
  mounted() {
    let menu = document.getElementById('menuProfile');
    if (menu) {
      this.menuHtml = menu.innerHTML;

      this.$nextTick(() => {
        this.$refs.menu.dispatchEvent(
            new CustomEvent('fragment-loaded', { bubbles: true })
        )
      })
    }
  }
}
</script>
