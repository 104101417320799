<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 509 200" fill-rule="evenodd"
       clip-rule="evenodd">
    <path fill="none" d="M0 0h508.802v199.8H0z" />
    <clipPath id="a">
      <path d="M0 0h508.802v199.8H0z" />
    </clipPath>
    <g clip-path="url(#a)">
      <circle cx="218.3" cy="17.3" r="16.8" fill="#2b406e" />
      <path
          d="M61 164.2c-.9 1.8-4.1 5.9-8.9 5.9-3.6 0-4.6-2.3-4.6-5.6 0-5.9 5-11.9 10.2-11.9 2.5 0 4.2.9 4.2 3.1 0 4.4-7.1 6.7-10.1 7.8 0 1.5.3 4.1 2.7 4.1 2.5 0 5-3 5.7-4.1l.8.7zm-9.1-1.8c1.7-.5 6.2-2.8 6.2-6.4 0-1.3-.5-2-1.8-2-2.8 0-4.4 7-4.4 8.4zm18.1.4c-.3 1.6-.4 2.5-.4 3.1 0 .9.1 1.7 1.1 1.7 1.2 0 2.8-2.2 3.5-3l.7.7c-1.3 1.9-3.5 4.7-7.3 4.7-1.3 0-2.1-.9-2.1-2.7 0-.6.2-2.3.4-3.3l1.7-9.4-2.8-.3.1-.7 3-.4.2-1.1c1.4-1.3 2.8-2.3 4.3-3.3l.6.2c-.3.8-1.1 2.9-1.3 4.2l4.2.4-.1.7-4.3.3-1.5 8.2zm-49.8 20.8c.3-.8 1.7-2.3 3.1-2.3 1 0 1.4.9 1.4 2.2 0 5-5.9 15.3-11.8 15.3-1.3 0-2.1-1.2-2.1-2.6 0-1.1.1-2.2.3-3.3l.9-5.7c.1-.6.2-1.5.2-1.9 0-.5-.1-1.1-.6-1.1-.6 0-1.7 1.2-2.1 1.5l-.6-.8c.7-.8 3.5-3.7 5.5-3.7 1.2 0 1.8.7 1.8 2.4 0 .8-.2 2.3-.4 3.5l-.7 4.7c-.2 1-.3 1.9-.3 2.9 0 1.1.3 1.5 1.2 1.5 1.5 0 4.9-4.9 4.9-8.9 0-2.2-.5-2.9-.7-3.5v-.2zm5 9.2c0-5.9 4.2-11.6 9.8-11.6 3.3 0 5.5 1.4 5.5 5.6 0 6.4-4.6 11.9-9.6 11.9-3.6 0-5.7-1.4-5.7-5.9zm6.7 4.5c3 0 4.4-6.3 4.4-10.2 0-3.1-.4-4.4-2.4-4.4-2.7 0-4.3 5.8-4.3 9.9-.1 2.4.4 4.7 2.3 4.7zm19.3-2.6c-.6.9-3.6 4-6 4-1.1 0-1.4-.9-1.4-1.8 0-.7.2-1.9.4-2.9l1.4-7.5c.1-.6.2-1.2.2-1.4 0-.6-.1-.8-.6-.8s-1.8 1.3-2.2 1.6l-.6-.8c1-1.1 4-3.7 6.2-3.7 1 0 1.3.6 1.3 1.7 0 .3-.1 1-.4 2.4l-1.4 7.5c-.1.8-.3 1.7-.3 2.1 0 .4.1.8.6.8.6 0 1.7-1.3 2.1-1.8l.7.6zm-4.1-17.5c0-1.5 1-2.7 2.5-2.7 1.2 0 1.9.6 1.9 1.9 0 1.5-1.1 2.7-2.4 2.7-1.2 0-2-.6-2-1.9z"
          fill-rule="nonzero" />
      <path
          d="M67.8 177.2c0-1.5 1-2.7 2.5-2.7 1.2 0 1.9.6 1.9 1.9 0 1.5-1.1 2.7-2.4 2.7-1.2-.1-2-.7-2-1.9z"
          fill="#2b406e" fill-rule="nonzero" stroke="#fff" stroke-width=".4" />
      <path
          d="M61.6 194.7c-.6.9-3.6 4-6 4-1.1 0-1.4-.9-1.4-1.8 0-.7.2-1.9.4-2.9l2.8-16.6c.1-.6.2-1.2.2-1.8 0-.8-.3-.9-.7-.9-.5 0-1.8.6-2.1.8l-.4-.8c1.1-.7 4.6-2.4 6.2-2.4.7 0 1.2.1 1.2 1.1 0 .5-.1 1.3-.3 2.5l-2.9 17c-.1.7-.3 1.7-.3 2.1 0 .4.1.8.6.8.6 0 1.7-1.3 2.1-1.8l.6.7zm10.9 0c-.6.9-3.6 4-6 4-1.1 0-1.4-.9-1.4-1.8 0-.7.2-1.9.4-2.9l1.4-7.5c.1-.6.2-1.2.2-1.4 0-.6-.1-.8-.6-.8s-1.8 1.3-2.2 1.6l-.6-.8c1-1.1 4-3.7 6.2-3.7 1 0 1.3.6 1.3 1.7 0 .3-.1 1-.4 2.4l-1.4 7.5c-.1.8-.3 1.7-.3 2.1 0 .4.1.8.6.8.6 0 1.7-1.3 2.1-1.8l.7.6zm15.1-1.9c-.9 1.8-4.1 5.9-8.9 5.9-3.6 0-4.6-2.3-4.6-5.6 0-5.9 5-11.9 10.2-11.9 2.5 0 4.2.9 4.2 3.1 0 4.4-7.1 6.7-10.1 7.8 0 1.5.3 4.1 2.7 4.1 2.5 0 5-3 5.7-4.1l.8.7zm-9.1-1.8c1.7-.5 6.2-2.8 6.2-6.4 0-1.3-.5-2-1.8-2-2.9.1-4.4 7-4.4 8.4zm14-1.5c.2-1.3.5-3 .5-3.8 0-.8-.1-1.5-.6-1.5-.8 0-1.7 1-2.2 1.5l-.6-.8c.6-.9 3.6-3.7 5.6-3.7.9 0 1.4.6 1.4 1.7 0 .8-.2 2.1-.4 2.7l.1.1c.8-1.3 2.5-4.4 4.7-4.4 1.3 0 2.3.5 3.1.9-.4.9-1.3 2.9-2.6 2.9-.8 0-2-.9-2.9-.9-1.3 0-2.4 5.5-2.8 8l-1 5.9-3.9.5 1.6-9.1zm19.7-4.3c-1.2-1.4-1.9-2.5-3.4-2.5-.8 0-1.5.8-1.5 1.6 0 3 5.8 3.6 5.8 8.9 0 3.1-2.9 5.5-6.6 5.5-3 0-4-1-4.7-2.2.5-1.4.9-1.9 2.2-2.8h.4c.5 1 1.7 3.6 4 3.6 1 0 1.8-.9 1.8-2.1 0-3.6-5.7-3.9-5.7-8.9 0-2.8 3-5.1 5.8-5.1 2.3 0 3 .6 3.7 1.5-.3 1.2-.6 1.6-1.5 2.5h-.3z"
          fill-rule="nonzero" />
      <path
          d="M486.7 111.6c-26.2-13.8-51.4-16.8-51.4-36.3 0-14.5 13.6-19.8 26.2-19.8 15.7 0 22.9 9 33.3 19.5V55c-5-2.1-27.6-6.4-38.6-6.4-11.8 0-32.5 4.8-36.5 23.2-.1.4-.3.6-.6.6-.2 0-.5-.3-.6-.4l-1.6-23.3s-5.5 1.3-12.6 1.4h-78.9s5.2 8.2 5.2 16.7v83.7s-4.4-.4-8.3 5.7c-3.6 5.5-14.5 14.8-23.6 14.8-3.8 0-23.6.5-29.3 0-5.7-.5-5.2-4.7-5.2-4.7 0-6.2 14.2-94.9 15.2-99.6C281 60 288.1 50 288.1 50h-31s2.9 7.1 2.9 15.7c0 5.2-13.7 89.6-14.8 95.3-1.1 5.7-2.7 12-8.5 12-5.7 0-7.3-5.8-7.3-12V65.7c0-8.6 3.8-15.7 3.8-15.7h-29.1s4.3 11 4.3 16.7c0 2.3 1.2 21.8 1.2 40.4 0 .6-.5 1-1 1-.6 0-.9-.4-1-1-6.1-33.5-31.8-57.8-67-57.8-2.1-.1-6.4.2-7.6.3-7.2.7-7.2 2.3-35 2.3-3.3 0-9.5-1.8-9.5-1.8.2.2 1.3 13.7-8.7 30-10.3 16.8-57.1 70.8-57.1 70.8s-.6-76.2 0-81c.7-5.2 4.5-16.8 4.5-16.8H1S5.2 63.8 5.2 80v100.5s36.5-40.2 68.5-75.7c-.6 3.7-.8 6.8-.8 10.6 0 35.8 29.4 64.9 66 66v-.5h.1v.5h1.9c32.2 0 59.4-23.9 66.4-53.1v.1c.1-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 18.3-.6 35.1-1.1 37.9-2 11.8-7.7 14.3-6.1 14.3l120.7-.1 4.4-7c.2-.3.3-.7.7-.7.4 0 .6.3.7.7l2.3 7h49.3v-.5h.1v.5h87.2c13.1 0 39.1-11.8 39.1-35.7.2-20.3-14.8-30.4-20.1-33.2zm-347.5 64.8v-.5.5zm1.7-.5h-1.8c-22.6-1.3-40.7-27.9-40.7-60.6 0-32 17.4-58.3 39.4-60.5.3 0 .6-.1.9-.1h2.2c23.4 0 42.4 27.1 42.4 60.6.1 33.5-18.9 60.6-42.4 60.6zm321.8-4c-15.5 0-30.5-7.1-41-19.7-.3-.3-.5-.7-.9-.7s-.7.4-1 .8c-7.1 13.7-25.1 18-29.9 18.4-1.3.1-4.7.2-8.9.3v.5h-.1v-.5c-8.6.2-20.4.3-24.2 0-5.7-.5-5.2-4.7-5.2-4.7v-48.6c0-4.3 2.4-4.8 4.8-4.8s20 .5 20 .5c3.8.6 13 1.9 20.5 10.8V94.4c0 .8-11.4 10.5-20.5 10.5-5.2 0-15.7 1-19 1-3.3 0-5.7-1-5.7-3.8V63.3c0-2.9 2.4-4.5 6.2-4.8 3.1-.2 7.1-.3 11-.3 5.7 0 11.5.4 15.7 1.2 7.1 1.4 19.9 5.7 25.2 16 3.4 6.8 11.9 24 27.9 35.4 11 7.8 50.9 13.5 52.1 34.3 1 17.7-11.7 26.8-27 26.8z"
          fill="#2b406e" fill-rule="nonzero" stroke="#2b406e" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VoilesVoiliersLogo',
  props: [
  ],
}
</script>