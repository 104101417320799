export function deleteCookie(cookieName) {
  document.cookie =
    cookieName +
    '=; Path=/; Domain=' +
    window.location.host +
    '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  document.cookie =
    cookieName +
    '=; Path=/; Domain=.' +
    window.location.host +
    '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export function getCookie(cookieName) {
  return document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .map((cookie) => cookie.split(/=(.*)/))
    .filter((cookie) => cookie)
    .find((cookie) => cookie[0] === cookieName)
}

export function setCookie(cookieName, cookieValue, expirationDate = null) {
  if (expirationDate === null) {
    expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + 7)
  }
  document.cookie = `${cookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/; Domain=${
    window.location.host
  }`
}
