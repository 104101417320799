<template>
  <div v-show="initialized" class="d-flex w-100" style="max-width:400px">
    <div ref="googleButton" class="w-100"></div>
    <div class="alert alert-danger my-1" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import eventBus from '../eventBus'
import {decodeJwt} from 'jose'
import translatable from "../../vue/mixins/translatable";
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "GoogleSso",
  mixins: [translatable],
  data() {
    return {
      googleClientId: window.googleIdentityClientId,
      error: null,
      initialized: false,
    };
  },
  methods: {
    handleGoogleCredentialResponse(googleResponse) {
      datalayerPush('click-login-SSO', {
        method: 'google',
      });
      fetch(window.urls.ssoGoogle, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          credential: googleResponse.credential,
        })
      }).then((response) => {
        response.json().then((result) => {
          if (result.success) {
            if (result.accountValidated) {
              eventBus.emit('connectionSuccess');
            } else {
              eventBus.emit('changeActivity', {
                activity: 'ConfirmActivity',
                data: {
                  email: result.email,
                },
              });
            }
          } else {
            if (result.mustRegister) {
              let data = decodeJwt(googleResponse.credential)

              eventBus.emit('changeActivity', {
                activity: 'RegisterActivity',
                data: {
                  sso: 'google',
                  ssoToken: googleResponse.credential,
                  ssoEmail: data.email,
                  ssoLastname: data.family_name,
                  ssoFirstname: data.given_name,
                },
              });
            } else {
              this.error = result.error;
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'error',
                'errorType': 'googleSsoFailed',
                'errorField': 'credentials',
                'errorMessage': result.error,
              });
            }
          }
        });
      });
    },
    initialize() {
      if (typeof google !== 'undefined' && google.accounts !== undefined && this.$refs.googleButton) {
        this.initialized = true;
        google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: this.handleGoogleCredentialResponse
        });

        google.accounts.id.renderButton(this.$refs.googleButton, {
          size: 'large',
          width: this.$refs.googleButton.clientWidth,
        });
      } else if (!document.getElementById('google-gsi')) {
        let elem = document.createElement('script');
        elem.id = 'google-gsi';
        elem.src = 'https://accounts.google.com/gsi/client';
        elem.async = true;
        elem.defer = true;

        document.body.appendChild(elem);
        setTimeout(() => {this.initialize()}, 100);
      } else {
        setTimeout(() => {
          this.initialize()
        }, 100);
      }
    },
  },
  mounted() {
    this.initialize();
  }
}
</script>