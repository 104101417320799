<template>
  <MenuScreen />
</template>

<script>
import MenuScreen from "../screen/MenuScreen";

export default {
  name: "MenuActivity",
  inheritAttrs: false,
  components: {MenuScreen},
}
</script>
