;(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined'
    ? (module.exports = factory())
    : typeof define === 'function' && define.amd
    ? define(factory)
    : ((global = global || self), (global.bsBreakpoints = factory()))
})(this, function () {
  'use strict'

  var breakPoints = {
    xs: {
      min: 0,
      max: 575,
    },
  }

  const computedStyle = window.getComputedStyle(document.documentElement)
  var minSmall = parseInt(
    computedStyle.getPropertyValue('--bs-breakpoint-sm'),
    10
  )
  var minMedium = parseInt(
    computedStyle.getPropertyValue('--bs-breakpoint-md'),
    10
  )
  var minLarge = parseInt(
    computedStyle.getPropertyValue('--bs-breakpoint-lg'),
    10
  )
  var minXlarge = parseInt(
    computedStyle.getPropertyValue('--bs-breakpoint-xl'),
    10
  )
  var minXXlarge = parseInt(
    computedStyle.getPropertyValue('--bs-breakpoint-xxl'),
    10
  )

  if (minSmall) {
    breakPoints.sm = {
      min: 576,
      max: 767,
    }
    if (minMedium) {
      breakPoints.md = {
        min: 768,
        max: 991,
      }
      if (minLarge) {
        breakPoints.lg = {
          min: 992,
          max: 1199,
        }
        if (minXlarge) {
          breakPoints.xl = {
            min: 1200,
            max: 1439,
          }
          if (minXXlarge) {
            breakPoints.xxl = {
              min: 1440,
              max: Infinity,
            }
          } else {
            breakPoints.xl.max = Infinity
          }
        } else {
          breakPoints.lg.max = Infinity
        }
      } else {
        breakPoints.md.max = Infinity
      }
    } else {
      breakPoints.sm.max = Infinity
    }
  }

  var breakPointsDetected = false
  var currentBreakpoint = null
  var Events = {
    INIT: 'init.bs.breakpoint',
    NEW: 'new.bs.breakpoint',
  }

  var getBreakPoints = function getBreakPoints() {
    const computedStyle = window.getComputedStyle(document.documentElement)
    var minSmall = parseInt(
      computedStyle.getPropertyValue('--bs-breakpoint-sm'),
      10
    )
    var minMedium = parseInt(
      computedStyle.getPropertyValue('--bs-breakpoint-md'),
      10
    )
    var minLarge = parseInt(
      computedStyle.getPropertyValue('--bs-breakpoint-lg'),
      10
    )
    var minXlarge = parseInt(
      computedStyle.getPropertyValue('--bs-breakpoint-xl'),
      10
    )
    var minXXlarge = parseInt(
      computedStyle.getPropertyValue('--bs-breakpoint-xxl'),
      10
    )

    if (minSmall) {
      breakPoints.xs.max = minSmall - 1
      breakPoints.sm.min = minSmall

      if (minMedium) {
        breakPoints.sm.max = minMedium - 1
        breakPoints.md.min = minMedium

        if (minLarge) {
          breakPoints.md.max = minLarge - 1
          breakPoints.lg.min = minLarge

          if (minXlarge) {
            breakPoints.lg.max = minXlarge - 1
            breakPoints.xl.min = minXlarge

            if (minXXlarge) {
              breakPoints.xl.max = minXXlarge - 1
              breakPoints.xxl.min = minXXlarge
            }
          }
        }
      }
    }

    breakPointsDetected = true
  }

  var _detectBreakPoint = function _detectBreakPoint() {
    var widthWindow = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )

    for (var key in breakPoints) {
      if (
        widthWindow <= breakPoints[key].max &&
        widthWindow >= breakPoints[key].min
      ) {
        return key
      }
    }

    return currentBreakpoint
  }

  var dispatchBreakpoint = function dispatchBreakpoint(
    breakPointKey,
    eventName
  ) {
    if (eventName === void 0) {
      eventName = Events.NEW
    }
    if (
      !currentBreakpoint ||
      currentBreakpoint !== breakPointKey ||
      eventName == Events.INIT
    ) {
      currentBreakpoint = breakPointKey

      if (typeof window.CustomEvent !== 'undefined') {
        var event = new window.CustomEvent(eventName, {
          detail: breakPointKey,
        })
        window.dispatchEvent(event)
      }
    }
  }

  return {
    init: function init() {
      getBreakPoints()
      dispatchBreakpoint(_detectBreakPoint(), Events.INIT)
      window.addEventListener('resize', function () {
        dispatchBreakpoint(_detectBreakPoint())
      })

      return _detectBreakPoint()
    },
    detectBreakpoint: function detectBreakpoint() {
      if (!breakPointsDetected) {
        getBreakPoints()
      }

      currentBreakpoint = _detectBreakPoint()
      return currentBreakpoint
    },
    getCurrentBreakpoint: function getCurrentBreakpoint() {
      return currentBreakpoint
    },
  }
})
