<template>
  <form
      ref="form"
      @submit.prevent="onSubmit"
      class="needs-validation no-loader d-flex flex-vertical flex-grow-1"
      :class="{'was-validated': formValidated}"
      novalidate
  >
    <div class="side-panel-content">
      <div class="bg-white p-5 px-md-10">
        <p class="font-h2 text-center my-4">{{ translate(titleKey) }}</p>
      </div>
      <div class="bg-white p-5 px-md-10">
        <p class="fs-16 text-center" v-if="!emailSent">{{ translate(messageKey) }}</p>
        <div class="alert alert-success" v-if="emailSent">{{ translate(messageEmailSentKey) }}</div>
      </div>

      <div class="bg-white p-5 px-md-10 flex-grow-1">
        <div class="form-group my-5">
          <label for="input-email" class="form-label">{{ translate('front.user_panel.forgot_password.email_label') }}</label>
          <input type="email" name="email" id="input-email" class="form-control" required="required"
                 :disabled="emailSent" v-model="email"/>
          <div class="invalid-feedback">{{ translate('front.user_panel.forgot_password.invalid_email') }}</div>
        </div>
      </div>
    </div>

    <div class="side-panel-footer p-5 px-md-10 d-flex justify-content-between">
      <a href="#" class="btn btn-white border-grey1 border-1" v-if="!emailSent"
         @click.prevent="back">{{ translate('front.user_panel.forgot_password.previous') }}</a>
      <div class="flex-grow-1"></div>
      <input type="submit" class="btn btn-primary"
             :value="translate('front.user_panel.forgot_password.submit_button')" v-if="!emailSent"/>
      <a href="#" class="btn btn-primary" v-if="emailSent"
         @click.prevent="back">{{ translate('front.user_panel.forgot_password.back_button') }}</a>
    </div>
  </form>
</template>

<script>
import translatable from '../../vue/mixins/translatable';
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "ForgotPasswordForm",
  mixins: [translatable],
  data() {
    return {
      emailSent: false,
      formValidated: false,
      email: null,
      title: null,
      message: null,
      messageEmailSent: null,
    };
  },
  props: {
    action: {
      type: String,
      default: 'resetPassword',
    },
  },
  computed: {
    submitUrl() {
      return (this.action == 'sendLink') ? window.urls.requestLoginLink : window.urls.requestPasswordReset;
    },
    titleKey() {
      if (this.action === 'resetPassword') {
        return 'front.user_panel.forgot_password.title';
      } else {
        return 'front.user_panel.magic_link.title';
      }
    },
    messageKey() {
      if (this.action === 'resetPassword') {
        return 'front.user_panel.forgot_password.subtitle';
      } else {
        return 'front.user_panel.magic_link.subtitle';
      }
    },
    messageEmailSentKey() {
      if (this.action === 'resetPassword') {
        return 'front.user_panel.forgot_password.confirmation_message';
      } else {
        return 'front.user_panel.magic_link.confirmation_message';
      }
    },
  },
  methods: {
    onSubmit() {
      this.formValidated = true;
      if (!this.$refs.form.checkValidity()) {
        return;
      }

      fetch(this.submitUrl, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.email,
        })
      }).then((response) => {
        if (Math.floor(response.status / 100) === 2) {
          this.emailSent = true;
        }
      });
    },
    back() {
      eventBus.emit('changeActivity', {
        activity: 'LoginActivity',
      });
    },
  },
  mounted() {
    datalayerPush((this.action === 'sendLink') ? 'vpv-login-magicLink' : 'vpv-login-resetPassword');
  },
}
</script>
