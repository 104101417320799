<template>
  <svg viewBox="25 0 265 66" xmlns="http://www.w3.org/2000/svg" class="logo-infoconnect">
    <title>Composant/logo/logo_Infoconnect</title>
    <g id="Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Assets-Infoconnect" transform="translate(-850.000000, -50.000000)" fill-rule="nonzero">
        <g id="Composant/logo/logo_Infoconnect" transform="translate(851.000000, 51.000000)">
          <path d="M32.1441613,21.4641228 L32.1441613,15.8739112 L37.1744272,15.8739112 L37.1744272,21.4641228 L32.1441613,21.4641228 Z M32.1441613,49.3484032 L32.1441613,25.3467441 L37.1744272,25.3467441 L37.1744272,49.3007051 L32.1441613,49.3484032 Z" id="Shape"></path>
          <path d="M64.6598762,49.3007051 L59.6772454,49.3007051 L59.6772454,36.0311074 C59.6772454,33.7988387 59.3088674,32.1802848 58.5721113,31.1754459 C57.8333038,30.1562259 56.6265855,29.5844349 55.371033,29.6586479 C54.3732873,29.6704447 53.3930133,29.9223808 52.5129273,30.3931978 C51.5490716,30.8909292 50.6813033,31.5563438 49.9501593,32.3583575 C49.1940567,33.18021 48.6252068,34.1566701 48.282931,35.2202406 L48.282931,49.3007051 L43.214557,49.3007051 L43.214557,25.5279967 L47.787526,25.5279967 L47.787526,30.2978017 C48.3923274,29.2443701 49.1984093,28.3205268 50.1597537,27.5790129 C51.1677333,26.8071946 52.2981567,26.2105999 53.5037373,25.814185 C54.783256,25.3812923 56.125958,25.1652733 57.4765041,25.1750311 C58.7130557,25.1307526 59.9410777,25.3963559 61.0491361,25.9477395 C61.961062,26.432865 62.7198694,27.1629436 63.2403504,28.0559934 C63.7689967,29.011207 64.129916,30.0502212 64.3073765,31.1277478 C64.538627,32.3447963 64.6566324,33.5807006 64.6598762,34.8195769 L64.6598762,49.3007051 Z" id="Path"></path>
          <path d="M71.7003431,49.3007051 L71.7003431,29.8399005 L68.4992648,29.8399005 L68.4992648,26.0240564 L71.7003431,26.0240564 L71.7003431,25.0700954 C71.6679677,23.3278762 71.9921934,21.5974692 72.653045,19.9854832 C73.2068323,18.6489441 74.1286788,17.4975944 75.3110832,16.6656989 C76.5107494,15.8736283 77.923492,15.4675521 79.3600662,15.5018664 C80.3239328,15.5060636 81.2837011,15.6277949 82.2181718,15.8643716 C83.1619681,16.10393 84.0735767,16.456244 84.9333721,16.9137287 L83.837765,20.7295728 C83.3355584,20.4334684 82.7977126,20.2026584 82.2372258,20.0427209 C81.6440879,19.8623172 81.0279277,19.7691283 80.4080382,19.7660722 C79.3355722,19.7190833 78.3153244,20.2316863 77.7118919,21.1206968 C77.0040471,22.2617666 76.6708864,23.5961709 76.7591901,24.9365409 L76.7591901,26.1194525 L82.8850631,26.1194525 L82.8850631,29.9352966 L76.6925009,29.9352966 L76.6925009,49.3961012 L71.7003431,49.3007051 Z" id="Path"></path>
          <path d="M96.9469428,49.7586064 C95.2067515,49.7942949 93.4779694,49.4695169 91.8690418,48.8046454 C90.4117782,48.1739048 89.0963017,47.2557474 88.0010722,46.1049357 C86.9286904,44.9662228 86.0845674,43.6319764 85.5145203,42.1746163 C84.9295747,40.6960363 84.6320816,39.1191814 84.6380346,37.5288262 C84.621418,35.8972693 84.9190075,34.2777974 85.5145203,32.7590212 C86.0845674,31.3016611 86.9286904,29.9674147 88.0010722,28.8287018 C89.1163812,27.6735063 90.4552011,26.7581402 91.9357309,26.1385317 C93.5393698,25.4783296 95.2609623,25.1536841 96.9945779,25.1845707 C98.7255928,25.1478599 100.445253,25.4727529 102.043898,26.1385317 C104.973619,27.4261499 107.270562,29.8291129 108.427,32.8162588 C109.014726,34.3369609 109.308917,35.9555283 109.293959,37.5860639 C109.29834,39.1753841 109.004245,40.751359 108.427,42.231854 C107.853471,43.6896531 107.006152,45.0238387 105.930921,46.1621734 C104.83045,47.3154907 103.508228,48.2338325 102.043898,48.861883 C100.424688,49.5089783 98.6894638,49.8142617 96.9469428,49.7586064 Z M89.8112057,37.4620489 C89.7821472,38.8906016 90.1096094,40.3038293 90.7639076,41.5736209 C91.3541046,42.7322094 92.2337017,43.7181344 93.3171486,44.4355039 C94.3816881,45.1215829 95.6238776,45.4798062 96.8897807,45.4657818 C98.1832209,45.4849039 99.4542967,45.1269414 100.548156,44.4355039 C101.633961,43.71807 102.51664,42.7323686 103.110924,41.5736209 C104.38067,39.0068293 104.38067,35.9935855 103.110924,33.4267939 C102.511597,32.2625766 101.618162,31.275868 100.519575,30.5649108 C99.4512741,29.8782764 98.2068348,29.5170446 96.9374158,29.5250933 C95.6480061,29.5084821 94.3844965,29.888036 93.3171486,30.6126089 C92.2443205,31.3421986 91.3674242,32.3250964 90.7639076,33.4744919 C90.1182907,34.7029782 89.7907282,36.0739993 89.8112057,37.4620489 Z" id="Shape"></path>
          <path d="M124.327595,49.7586064 C122.804875,49.7713779 121.298307,49.4455553 119.916585,48.8046454 C118.526297,48.1452646 117.27482,47.2256538 116.229629,46.0953961 C115.157529,44.9456023 114.313676,43.6020703 113.743077,42.1364579 C113.143109,40.6183753 112.839118,38.9992634 112.847537,37.3666528 C112.814612,35.2101082 113.33626,33.08134 114.362333,31.1849855 C115.324161,29.394775 116.74472,27.8933136 118.478005,26.8349233 C120.222385,25.7635962 122.233746,25.2080047 124.27996,25.2322688 C126.158572,25.2056502 128.009144,25.6903364 129.634144,26.6345915 C131.196383,27.5390342 132.44097,28.9052249 133.197249,30.5458316 L132.244547,30.8701783 C131.494205,29.4149032 130.335126,28.2111846 128.910091,27.4072999 C127.459982,26.5766045 125.817215,26.1423409 124.146581,26.1480713 C122.293277,26.1284711 120.473756,26.6452357 118.906721,27.6362505 C117.330195,28.6333012 116.039885,30.0239442 115.162603,31.6715056 C114.209881,33.426276 113.727384,35.3982516 113.762131,37.3952717 C113.750442,39.3919665 114.238472,41.3597832 115.181657,43.1190377 C116.078816,44.8196447 117.389424,46.2664993 118.992464,47.3260058 C120.535482,48.3646456 122.354027,48.916247 124.21327,48.9095811 C125.467296,48.908514 126.709378,48.6655992 127.871646,48.1941103 C128.983614,47.72899 130.008179,47.0773059 130.901238,46.2671091 C131.676812,45.6355558 132.255394,44.7946761 132.568466,43.8440481 L133.521168,44.1111572 C133.15182,45.2179687 132.494031,46.2059568 131.615764,46.9730402 C130.64383,47.8426768 129.519842,48.525076 128.300361,48.985898 C127.035642,49.4886916 125.688325,49.7507466 124.327595,49.7586064 L124.327595,49.7586064 Z" id="Path"></path>
          <path d="M185.300515,49.3007051 L184.347813,49.3007051 L184.347813,36.0692659 C184.347813,32.615927 183.861935,30.0879303 182.909233,28.4375778 C181.93196,26.8270655 180.137259,25.8990688 178.260048,26.033596 C176.704261,26.0648517 175.177354,26.4601505 173.801403,27.1878888 C172.374706,27.8814004 171.101525,28.8545787 170.057285,30.0497719 C169.003215,31.2648388 168.223315,32.6932906 167.7708,34.2376607 L167.7708,49.3007051 L166.818098,49.3007051 L166.818098,25.5279967 L167.7708,25.5279967 L167.7708,31.6715056 C168.445689,30.3886561 169.346114,29.2382075 170.428838,28.2754044 C171.515945,27.3087124 172.759535,26.5344741 174.106268,25.985898 C175.422355,25.4488234 176.829341,25.1702929 178.250521,25.1654915 C179.339552,25.135948 180.420119,25.3647687 181.403964,25.8332642 C182.298795,26.2860408 183.05561,26.9713694 183.595178,27.8175031 C184.234824,28.8577583 184.671278,30.0102246 184.881326,31.2136043 C185.171563,32.8154592 185.308737,34.4413581 185.290988,36.0692659 L185.300515,49.3007051 Z" id="Path"></path>
          <path d="M211.099681,49.3007051 L210.14698,49.3007051 L210.14698,36.0692659 C210.14698,32.615927 209.661102,30.0879303 208.7084,28.4375778 C207.731126,26.8270655 205.936425,25.8990688 204.059215,26.033596 C202.516365,26.0707556 201.003171,26.4658817 199.638678,27.1878888 C198.182071,27.8933911 196.885165,28.8900821 195.82787,30.1165491 C194.7738,31.3316161 193.993901,32.7600679 193.541386,34.304438 L193.541386,49.3007051 L192.588684,49.3007051 L192.588684,25.5279967 L193.541386,25.5279967 L193.541386,31.6715056 C194.213247,30.386695 195.114075,29.2357324 196.199424,28.2754044 C197.286531,27.3087124 198.530121,26.5344741 199.876853,25.985898 C201.192666,25.4479055 202.599832,25.1693394 204.021106,25.1654915 C205.11004,25.1370295 206.190357,25.3657974 207.17455,25.8332642 C208.067532,26.2887326 208.823706,26.9734807 209.365764,27.8175031 C210.00541,28.8577583 210.441864,30.0102246 210.651912,31.2136043 C210.942149,32.8154592 211.079323,34.4413581 211.061573,36.0692659 L211.099681,49.3007051 Z" id="Path"></path>
          <path d="M228.172099,49.7586064 C226.649379,49.7713779 225.142812,49.4455553 223.761089,48.8046454 C222.37656,48.1495857 221.133674,47.2291534 220.102714,46.0953961 C219.038146,44.9344264 218.198223,43.5855381 217.625689,42.1173787 C217.029387,40.5752761 216.728799,38.9344222 216.739677,37.2807964 C216.716322,35.1568669 217.234411,33.0620747 218.244945,31.1945251 C219.200732,29.4164509 220.594495,27.9124804 222.293928,26.8253837 C223.977392,25.7403948 225.941523,25.173261 227.943451,25.1941103 C229.968061,25.1797676 231.953475,25.7529852 233.659662,26.8444629 C235.387192,27.9477915 236.811809,29.466112 237.803915,31.2613024 C238.848837,33.1622324 239.383868,35.3018995 239.356819,37.4715886 L239.356819,37.9676483 L217.692378,37.9676483 C217.815887,39.9214111 218.393311,41.8193035 219.378661,43.5101618 C220.308522,45.1077626 221.617418,46.4511543 223.189468,47.4214019 C224.710568,48.3770496 226.471673,48.879942 228.267369,48.8714226 C230.068419,48.879202 231.837393,48.3943177 233.383378,47.4691 C234.857945,46.6502692 236.035345,45.3844776 236.746416,43.8535877 L237.622902,44.0825384 C237.154898,45.1835701 236.441162,46.1625831 235.536484,46.9444214 C234.555848,47.833574 233.414293,48.5265366 232.173447,48.985898 C230.898845,49.4889644 229.542121,49.7509643 228.172099,49.7586064 Z M217.692378,37.0232269 L238.451752,37.0232269 C238.371828,35.0404823 237.823297,33.1050072 236.851213,31.3757777 C235.960844,29.7931988 234.686187,28.4610784 233.145203,27.502696 C230.010788,25.6075221 226.085708,25.6075221 222.951293,27.502696 C221.410932,28.464013 220.141725,29.8038599 219.264336,31.3948569 C218.316521,33.1255657 217.778687,35.0512698 217.692378,37.0232269 L217.692378,37.0232269 Z" id="Shape"></path>
          <path d="M254.580995,49.7586064 C253.058372,49.7704278 251.552011,49.4446496 250.169985,48.8046454 C248.78406,48.1426226 247.536177,47.2232783 246.492556,46.0953961 C245.421404,44.943044 244.574836,43.6003342 243.996477,42.1364579 C243.404238,40.6164533 243.103646,38.9982294 243.110464,37.3666528 C243.074189,35.2109009 243.592569,33.0820759 244.615733,31.1849855 C245.582745,29.3985338 247.002149,27.8982936 248.731405,26.8349233 C250.47916,25.7633031 252.493646,25.2077607 254.542887,25.2322688 C256.418627,25.2045007 258.266434,25.6893255 259.887544,26.6345915 C261.450574,27.540927 262.697731,28.9062874 263.460176,30.5458316 L262.507475,30.8701783 C261.756418,29.4122514 260.593433,28.2079167 259.163491,27.4072999 C257.71459,26.5736578 256.071018,26.1391814 254.399982,26.1480713 C252.543391,26.1007391 250.713059,26.5945546 249.13154,27.5694732 C247.552081,28.565924 246.258539,29.9565112 245.377895,31.6047283 C244.428077,33.3602386 243.948849,35.3322727 243.98695,37.3284944 C243.971928,39.324417 244.456694,41.2922843 245.396949,43.0522605 C246.294109,44.7528674 247.604716,46.1997221 249.207756,47.2592285 C250.747067,48.2981841 252.562693,48.8499067 254.419036,48.8428038 C255.673061,48.8417368 256.915143,48.5988219 258.077411,48.1273331 C259.21715,47.695787 260.271799,47.0660368 261.192746,46.2671091 C261.971126,45.6381545 262.550327,44.7963747 262.859974,43.8440481 L263.812676,44.1111572 C263.444589,45.2185859 262.786593,46.2068842 261.907272,46.9730402 C260.932401,47.8386954 259.809183,48.5206274 258.59187,48.985898 C257.314849,49.4920636 255.954418,49.7541551 254.580995,49.7586064 Z" id="Path"></path>
          <path d="M278.665298,48.423061 C278.004556,48.8399182 277.298081,49.1792183 276.559827,49.4342596 C275.835265,49.6597852 275.079664,49.7692507 274.320978,49.7586064 C273.614061,49.7661358 272.915409,49.6059526 272.282196,49.2911655 C271.674735,48.9995314 271.155339,48.5518648 270.776927,47.9937785 C270.396399,47.4311203 270.196929,46.7653403 270.205306,46.0858565 L270.205306,26.4437993 L266.775579,26.4437993 L266.775579,25.4898382 L270.205306,25.4898382 L270.205306,17.2476151 L271.158008,17.2476151 L271.158008,25.5279967 L276.874219,25.5279967 L276.874219,26.4819577 L271.158008,26.4819577 L271.158008,46.0667773 C271.157841,46.8908297 271.537538,47.6688275 272.186926,48.1750311 C272.806285,48.6445734 273.563203,48.8960947 274.340032,48.8905019 C275.269689,48.9208565 276.193395,48.7312954 277.036178,48.3372045 C277.769759,47.9746993 278.150839,47.7743675 278.17942,47.7457487 L278.665298,48.423061 Z" id="Path"></path>
          <path d="M158.948781,28.3612609 L158.948781,24.3260058 C158.948781,24.0016591 158.948781,23.6773123 158.948781,23.3720448 C158.696923,18.4712945 154.639341,14.6361076 149.738679,14.666563 C144.838017,14.697383 140.828577,18.5830029 140.637851,23.4865201 C140.637851,23.7154708 140.637851,23.953961 140.637851,24.1829117 L140.637851,28.3898797 C136.669351,32.1482368 135.390552,37.9496708 137.410422,43.0315239 C139.430291,48.113377 144.340519,51.4483695 149.802843,51.4483695 C155.265166,51.4483695 160.175394,48.113377 162.195264,43.0315239 C164.215133,37.9496708 162.936335,32.1482368 158.967835,28.3898797 L158.948781,28.3612609 Z M144.05805,24.4500207 L144.05805,23.9825798 C144.044436,21.9376752 145.121359,20.0408324 146.883154,19.0065738 C148.644948,17.9723152 150.823956,17.9577693 152.599365,18.9684154 C154.374774,19.9790614 155.476858,21.8613583 155.490473,23.906263 C155.490473,24.125674 155.490473,24.3355454 155.490473,24.5454168 L155.490473,25.985898 C151.879748,24.2371937 147.668776,24.2371937 144.05805,25.985898 L144.05805,24.4500207 Z M152.765746,44.8647864 L146.801832,44.8647864 L148.145142,38.4350892 C146.546986,37.6592536 145.695973,35.8808121 146.093461,34.1475026 C146.490949,32.4141931 148.031534,31.1856513 149.807606,31.1856513 C151.583679,31.1856513 153.124263,32.4141931 153.521751,34.1475026 C153.91924,35.8808121 153.068226,37.6592536 151.470071,38.4350892 L152.765746,44.8647864 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InfoconnectLogo',
  props: [],
}
</script>

<style lang="scss" scoped>
</style>