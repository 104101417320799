<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">

    <g>
      <path d="M27.1001 2.6001V36.9001H48.6001C46.3001 24.1001 39.3001 12.3001 28.7001 3.9001L27.1001 2.6001Z"
            class="stroke-color1"
            :stroke="color1"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
      <path d="M18.6997 36.8999H23.6997"
            class="stroke-color1"
            :stroke="color1"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
      <path d="M22.7999 9.80029L17.3999 27.0003"
            class="stroke-color1"
            :stroke="color1"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
      <path d="M20.2999 41.3999H48.5999L41.4999 47.4999H18.8999"
            class="stroke-color1"
            :stroke="color1"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
      <path d="M16.7999 45.3999C15.4999 46.7999 13.8999 47.5999 12.1999 47.5999C8.1999 47.5999 4.8999 43.2999 4.8999 37.9999C4.8999 32.6999 8.1999 28.3999 12.1999 28.3999C13.8999 28.3999 15.4999 29.1999 16.7999 30.4999"
            class="stroke-color2"
            :stroke="color2"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
      <path d="M1.7998 35.6997H15.3998"
            class="stroke-color2"
            :stroke="color2"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
      <path d="M1.7998 40.1997H15.3998"
            class="stroke-color2"
            :stroke="color2"
            :stroke-width="strokeWidth"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "BoatEuro",
  mixins: [svgIcon],
}
</script>