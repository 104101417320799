<template>
  <div>
    <label for="input-password" class="form-label">{{ translate('front.user_panel.password.label') }}&nbsp;*</label>
    <div class="input-reveal position-relative">
      <input
          :type="inputType"
          name="password"
          id="input-password"
          class="form-control"
          :class="{
            'is-invalid': formValidated && strength < 4,
            'is-valid': formValidated && strength == 4,
          }"
          no-checker="no-checker"
          data-reveal-password
          v-model="password"
          data-test="login-panel-registration-password"
      />
      <div class="reveal-button reveal-button-closed" @click="toggleReveal">
        <svg v-if="!reveal" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 001.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0014.828 8a13.133 13.133 0 00-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 001.172 8z"></path>
          <path fill-rule="evenodd"
                d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"></path>
        </svg>
        <svg v-if="reveal" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 00-2.79.588l.77.771A5.944 5.944 0 018 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"></path>
          <path fill-rule="evenodd"
                d="M11.297 9.176a3.5 3.5 0 00-4.474-4.474l.823.823a2.5 2.5 0 012.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 01-4.474-4.474l.823.823a2.5 2.5 0 002.829 2.829z"></path>
          <path fill-rule="evenodd"
                d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 001.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 018 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z"></path>
          <path fill-rule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"></path>
        </svg>
      </div>
    </div>
    <div class="d-flex flex-horizontal mt-1">
      <div class="flex-grow-1 m-1 ml-0 rounded-2" style="height: 0.25rem;" :style="{'background-color': color}"></div>
      <div class="flex-grow-1 m-1 rounded-2" style="height: 0.25rem;"
           :style="{'background-color': strength >= 2 ? color : 'var(--bs-grey3)'}"></div>
      <div class="flex-grow-1 m-1 rounded-2" style="height: 0.25rem;"
           :style="{'background-color': strength >= 3 ? color : 'var(--bs-grey3)'}"></div>
      <div class="flex-grow-1 m-1 mr-0 rounded-2" style="height: 0.25rem;"
           :style="{'background-color': strength >= 4 ? color : 'var(--bs-grey3)'}"></div>
    </div>
    <div class="d-flex flex-horizontal justify-content-between my-2" style="font-style: italic;">
      <div>
        <p :style="{color: lengthOk ? '#009900' : 'var(--bs-grey1)'}"><i
            :class="{bi: true, 'bi-check-circle': lengthOk, 'bi-x-circle': !lengthOk}"></i>
          {{ translate('front.user_panel.password.8char.message') }}</p>
        <p :style="{color: uppercaseOk ? '#009900' : 'var(--bs-grey1)'}"><i
            :class="{bi: true, 'bi-check-circle': uppercaseOk, 'bi-x-circle': !uppercaseOk}"></i>
          {{ translate('front.user_panel.password.uppercase.message') }}</p>
        <p :style="{color: numberOk ? '#009900' : 'var(--bs-grey1)'}"><i
            :class="{bi: true, 'bi-check-circle': numberOk, 'bi-x-circle': !numberOk}"></i>
          {{ translate('front.user_panel.password.digit.message') }}</p>
      </div>
      <!--suppress CssUnresolvedCustomProperty -->
      <div style="color: var(--bs-grey1);">{{ translate(`front.user_panel.password.strength.${strength}.label`) }}</div>
    </div>
    <div v-if="formValidated && strength < 4" class="alert alert-danger my-2">{{ invalidFeedback }}</div>
  </div>
</template>

<script>
import translatable from '../../vue/mixins/translatable';

export default {
  name: "PasswordStrengthIndicator",
  mixins: [translatable],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    formValidated: {
      type: Boolean,
      default: false,
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      password: this.modelValue,
      reveal: false,
    };
  },
  computed: {
    lengthOk() {
      return this.password !== null && this.password.length >= 8;
    },
    uppercaseOk() {
      return this.password !== null && this.password.match(/[A-Z]/) !== null;
    },
    numberOk() {
      return this.password !== null && this.password.match(/[0-9]/) !== null;
    },
    strength() {
      if (!this.password) {
        return 1;
      }

      let strength = 1;
      if (this.lengthOk) {
        strength += 1;
      }
      if (this.uppercaseOk) {
        strength += 1;
      }
      if (this.numberOk) {
        strength += 1;
      }

      return strength;
    },
    color() {
      switch (this.strength) {
        case 1:
          return '#cc0000';
        case 2:
          return '#ff6600';
        case 3:
          return '#ffcc00';
        case 4:
          return '#009900';
      }
    },
    inputType() {
      return this.reveal ? 'text' : 'password';
    },
  },
  methods: {
    toggleReveal() {
      this.reveal = !this.reveal;
    },
    emitStrength() {
      this.$emit('strengthChange', {
        strength: this.strength,
      });
    },
  },
  watch: {
    strength(newStrength, oldStrength) {
      if (newStrength !== oldStrength) {
        this.emitStrength();
      }
    },
    password(newPassword, oldPassword) {
      this.$emit('update:modelValue', newPassword);
    },
  },
  mounted() {
    this.emitStrength();
  },
}
</script>