<template>
  <form
      ref="form"
      @submit.prevent="onSubmit"
      class="needs-validation no-loader d-flex flex-vertical justify-content-between flex-grow-1"
      :class="{'was-validated': formValidated}"
      novalidate
  >
    <div class="side-panel-content">
      <div class="bg-white p-5 px-md-10">
        <p class="font-h2 text-center my-4">{{ translate('front.user_panel.reset_password.title') }}</p>
      </div>

      <div class="bg-white p-5 px-md-10">
        <div class="form-group my-5">
          <PasswordStrengthIndicator
              v-model="password"
              @strengthChange="onStrengthChange"
              :formValidated="formValidated"
              :invalidFeedback="translate('front.user_panel.reset_password.invalid_password')"
          />
        </div>
      </div>
    </div>
    <div class="side-panel-footer p-5 px-md-10 text-right">
      <input type="submit" class="btn btn-primary" :value="translate('front.user_panel.reset_password.submit_button')"/>
    </div>
  </form>
</template>

<script>
import PasswordStrengthIndicator from '../components/PasswordStrengthIndicator';
import translatable from '../../vue/mixins/translatable';
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "PasswordResetForm",
  components: {
    PasswordStrengthIndicator,
  },
  mixins: [translatable],
  data() {
    return {
      formValidated: false,
      password: null,
      strength: 0,
      error: null,
    };
  },
  methods: {
    onSubmit() {
      this.formValidated = true;
      if (!this.$refs.form.checkValidity()) {
        return;
      }

      fetch(window.urls.resetPassword, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: this.password,
        })
      }).then((response) => {
        response.json().then((result) => {
          if (result.success) {
            fetch(window.urls.login, {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                username: result.email,
                password: this.password,
              })
            }).then((response) => {
              response.json().then((result) => {
                if (result.success) {
                  if (result.accountValidated) {
                    eventBus.emit('connectionSuccess');
                  } else {
                    eventBus.emit('changeActivity', {
                      activity: 'ConfirmActivity',
                      data: {
                        email: result.email,
                      },
                    });
                  }
                } else {
                  eventBus.emit('changeActivity', {
                    activity: 'LoginActivity',
                    data: {
                      email: result.email,
                    },
                  });
                }
              });
            });
          } else {
            this.error = result.error;
          }
        });
      });
    },
    onStrengthChange(event) {
      this.strength = event.strength;
    },
  },
}
</script>
