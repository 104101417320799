<template>
  <OnboardingScreen :user-type="userType" />
</template>

<script>
import OnboardingScreen from '../screen/OnboardingScreen'

export default {
  name: "OnboardingActivity",
  inheritAttrs: false,
  components: {OnboardingScreen},
  data() {
    return {
      userInfo: null
    }
  },
  computed: {
    userType() {
      if (!this.userInfo) {
        return null
      }
      return this.userInfo.officeId ? 'pro' : 'individual'
    }
  },
  methods: {
    async fetchUserInfo() {
      const res = await fetch(window.urls.accountStatus)
      this.userInfo = await res.json()
    }
  },
  mount() {
    this.$emit('setTitle', {
      title: 'front.user_panel.onboarding.title'
    })
    this.fetchUserInfo()
  },
}
</script>
