<template>
  <form
      ref="form"
      @submit.prevent="onSubmit"
      class="needs-validation no-loader d-flex flex-vertical flex-grow-1"
      :class="{'was-validated': formValidated}"
      novalidate
  >
    <Tabs active="login"/>
    <div class="side-panel-content side-panel-padding">
      <div class="text-center">
        <p class="font-h2 my-4">{{ translate('front.user_panel.login_form.title') }}</p>
        <p class="fs-16">
          <span v-if="message">{{ message }}</span>
          <span v-else>{{ translate('front.user_panel.login_form.message') }}</span>
        </p>
      </div>
      <div class="">
        <div class="my-2" v-if="loginFailed">
          <div class="alert alert-danger">{{ translate('front.user_panel.login_form.login_error') }}</div>
        </div>
        <div class="form-group my-5">
          <label for="input-email" class="form-label">{{
              translate('front.user_panel.login_form.email')
            }}&nbsp;*</label>
          <input type="email" name="email" id="input-email" data-test="login-panel-email-input" class="form-control" required="required"
                 v-model="username"/>
          <div class="invalid-feedback">{{ translate('front.user_panel.login_form.invalid_email') }}</div>
        </div>
        <div class="form-group my-5">
          <label for="input-password"
                 class="form-label">{{ translate('front.user_panel.login_form.password') }}&nbsp;*</label>
          <div class="position-relative">
            <input :type="inputType" name="password" data-test="login-panel-password-input" id="input-password" class="form-control" required="required"
                   data-reveal-password
                   v-model="password"/>

            <div class="reveal-button reveal-button-closed" @click="toggleReveal">
              <svg v-if="!reveal" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 001.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0014.828 8a13.133 13.133 0 00-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 001.172 8z"></path>
                <path
                    fill-rule="evenodd"
                    d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"></path>
              </svg>
              <svg v-if="reveal" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 00-2.79.588l.77.771A5.944 5.944 0 018 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"></path>
                <path
                    fill-rule="evenodd"
                    d="M11.297 9.176a3.5 3.5 0 00-4.474-4.474l.823.823a2.5 2.5 0 012.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 01-4.474-4.474l.823.823a2.5 2.5 0 002.829 2.829z"></path>
                <path
                    fill-rule="evenodd"
                    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 001.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 018 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z"></path>
                <path
                    fill-rule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"></path>
              </svg>
            </div>
          </div>
          <div class="invalid-feedback">{{ translate('front.user_panel.login_form.password_required') }}</div>
        </div>
      </div>
      <div class="text-center">
        <div class="text-primary font-h3 strikethrough-like-title">
          <span>{{ translate('front.user_panel.login_form.forgot_password.title') }}</span>
        </div>
        <div class="my-5">
          <div class="my-3"><a href="#" class="link" @click.prevent="resetPassword">{{
              translate('front.user_panel.login_form.forgot_password.reset')
            }}</a></div>
          <div class="my-3"><a href="#" class="link" @click.prevent="sendLoginLink">{{
              translate('front.user_panel.login_form.forgot_password.login_link')
            }}</a></div>
        </div>
      </div>
    </div>
    <div class="side-panel-footer p-5 px-md-10 text-right">
      <input data-test="login-panel-submit-button" type="submit" class="btn btn-primary" :value="translate('front.user_panel.login_form.login_button')"/>
    </div>
  </form>
</template>

<script>
import Tabs from '../components/Tabs';
import translatable from '../../vue/mixins/translatable';
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "LoginForm",
  mixins: [translatable],
  components: {Tabs},
  props: {
    loginMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      message: this.loginMessage ? this.loginMessage : (window.requireAuthenticationMessage !== undefined ? window.requireAuthenticationMessage : null),
      username: null,
      password: null,
      formValidated: false,
      loginFailed: false,
      reveal: false,
    };
  },
  methods: {
    onSubmit(event) {
      this.loginFailed = false;
      this.formValidated = true;
      if (!this.$refs.form.checkValidity() || !this.username || !this.password) {
        return;
      }
      datalayerPush('click-login-login')

      fetch(window.urls.login, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password,
        })
      }).then((response) => {
        response.json().then((result) => {
          if (result.success) {
            if (result.accountValidated) {
              eventBus.emit('connectionSuccess', {
                close: true,
              });
            } else {
              eventBus.emit('changeActivity', {
                activity: 'ConfirmActivity',
                data: {
                  email: this.username,
                },
              });
            }
          } else {
            this.loginFailed = true;
            datalayerPush('error', {
              'errorType': 'loginFailed',
              'errorField': 'password',
              'errorMessage': result.error,
            });
          }
        });
      });
    },
    toggleReveal() {
      this.reveal = !this.reveal;
    },
    resetPassword() {
      eventBus.emit('changeActivity', {
        activity: 'PasswordResetActivity',
        data: {
          action: 'resetPassword',
        },
      });
    },
    sendLoginLink() {
      eventBus.emit('changeActivity', {
        activity: 'PasswordResetActivity',
        data: {
          action: 'sendLink',
        },
      });
    },
  },
  computed: {
    inputType() {
      return this.reveal ? 'text' : 'password';
    }
  },
  mounted() {
    this.$refs.form.querySelector('input').focus();

    datalayerPush('vpv-login-login');
  },
}
</script>
