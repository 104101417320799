<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
          d="M29.0098 19.9285C32.7235 19.9285 35.734 16.9727 35.734 13.3266C35.734 9.68041 32.7235 6.72461 29.0098 6.72461C25.2962 6.72461 22.2857 9.68041 22.2857 13.3266C22.2857 16.9727 25.2962 19.9285 29.0098 19.9285Z"
          class="stroke-color2"
          :stroke="color2"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M19.1823 28.7647C20.2167 24.3392 24.2069 21.002 29.0837 21.002C31.3744 21.002 33.4433 21.7274 35.1428 22.9608"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M28.8621 44.2754C28.8621 39.2695 33 35.2793 38.0246 35.2793C43.1231 35.2793 47.1872 39.342 47.1872 44.2754"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M38.0246 34.2775C41.4118 34.2775 44.1576 31.5816 44.1576 28.2559C44.1576 24.9303 41.4118 22.2344 38.0246 22.2344C34.6374 22.2344 31.8916 24.9303 31.8916 28.2559C31.8916 31.5816 34.6374 34.2775 38.0246 34.2775Z"
          class="stroke-color2"
          :stroke="color2"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M12.9014 26.7482C16.2886 26.7482 19.0344 24.0523 19.0344 20.7266C19.0344 17.401 16.2886 14.7051 12.9014 14.7051C9.51427 14.7051 6.76843 17.401 6.76843 20.7266C6.76843 24.0523 9.51427 26.7482 12.9014 26.7482Z"
          class="stroke-color2"
          :stroke="color2"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M3.73889 36.7461C3.73889 31.7402 7.87682 27.75 12.9015 27.75C18 27.75 22.064 31.8127 22.064 36.7461"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "Community",
  mixins: [svgIcon],
}
</script>