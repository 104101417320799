<template>
  <ul class="nav nav-tabs bg-skyblue fs-16 fw-bold pt-2">
    <li class="nav-item flex-grow-1 text-center">
      <component
        :is="active == 'login' ? 'span' : 'a'"
        :href="active != 'login' ? '#' : null"
        class="nav-link font-h3 border-0"
        :class="{
          active: active == 'login',
          inactive: active !== 'login',
        }"
        @click.prevent="onLoginTab"
      ><span>{{ translate('front.user_panel.tab.login') }}</span></component>
    </li>
    <li class="nav-item flex-grow-1 text-center">
      <component
        :is="active == 'register' ? 'span' : 'a'"
        :href="active != 'register' ? '#' : null"
        class="nav-link font-h3 border-0"
        :class="{
          active: active == 'register',
          inactive: active !== 'register',
        }"
        data-test="login-panel-registration-tab"
        @click.prevent="onRegisterTab"
      ><span>{{ translate('front.user_panel.tab.register') }}</span></component>
    </li>
  </ul>
</template>

<script>
import eventBus from "../eventBus";
import translatable from "../../vue/mixins/translatable";

export default {
  name: "Tabs",
  mixins: [translatable],
  props: ['active'],
  methods: {
    onRegisterTab() {
      if (this.active !== 'register') {
        eventBus.emit('changeActivity', {
          activity: 'RegisterActivity',
        });
      }
    },
    onLoginTab() {
      if (this.active !== 'login') {
        eventBus.emit('changeActivity', {
          activity: 'LoginActivity',
        });
      }
    },
  },
}
</script>