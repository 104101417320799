<template>
  <svg  class="primary" :class="classes" :width="iconSize"
        :height="iconSize" viewBox="0 0 50 50" :fill="fill"
        xmlns="http://www.w3.org/2000/svg">

    <g>
      <path
          d="M22.2478 31.3336H14.8761C14.7894 31.3336 14.7026 31.3336 14.6159 31.4182L8.97875 35.8143C8.71858 36.0679 8.28495 35.8143 8.28495 35.4761V31.7563C8.28495 31.5027 8.1115 31.3336 7.85132 31.3336H5.7699C3.60176 31.3336 1.86725 29.5583 1.86725 27.4447V12.2273C1.86725 10.3674 3.42831 8.8457 5.33627 8.8457H31.7009C33.6088 8.8457 35.1699 10.3674 35.1699 12.2273"
          class="stroke-color1"
          :stroke="color1"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      <path
          d="M35.3433 37.6738L43.8425 42.1544L41.5876 33.2776L49.1327 26.3453L39.246 26.0916L35.3433 16.623L31.4407 26.0916L21.554 26.3453L29.0991 33.2776L26.8442 42.1544L35.3433 37.6738Z"
          class="stroke-color2"
          :stroke="color2"
          :stroke-width="strokeWidth"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
import svgIcon from "../mixins/svgIcon";

export default {
  name: "Review",
  mixins: [svgIcon],
}
</script>