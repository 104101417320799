<template>
  <LoginForm
      :login-message="loginMessage"
  />
</template>

<script>
import LoginForm from "../screen/LoginForm";

export default {
  name: "LoginActivity",
  inheritAttrs: false,
  components: {LoginForm},
  props: {
    loginMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      login: null,
      password: null,
    }
  },
  methods: {},
}
</script>
