<template>
  <div v-show="initialized" class="d-flex w-100" style="max-width:400px">
    <button
        ref="infoconnectButton"
        class="btn btn-white btn-outline-primary fw-bold px-3 py-1 w-100 d-flex flex-horizontal align-items-center justify-content-center border-1"
        @click="login"
    >
      {{ translate('front.app_register.voiles-voilier.continue-with') }}
      <InfoconnectLogo class="ml-2" style="height: 32px" aria-label="infoconnect" />
    </button>
    <div class="alert alert-danger my-1" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import translatable from "../../vue/mixins/translatable";
import {datalayerPush} from "../../lib/datalayer";
import Infoconnect from "../service/infoconnect";
import InfoconnectLogo from "../../vue/images/InfoconnectLogo";
import {getCookie} from "../../lib/cookies";
import eventBus from "../eventBus";

export default {
  name: "InfoconnectSso",
  components: {InfoconnectLogo},
  mixins: [translatable],
  data() {
    return {
      error: null,
      initialized: false,
      //keycloak: null,
      infoconnect: null,
    };
  },
  methods: {
    login() {
      datalayerPush('click-login-SSO', {
        method: 'infoconnect',
      });
      localStorage.setItem('infoconnect-open-register', 'true');
      this.infoconnect.init({
        onLoad: 'login-required',
      }).then((authenticated) => {
        if (!authenticated) {
          this.infoconnect.keycloak.login();
        } else {
          this.infoconnect.keycloak.loadUserInfo().then((info) => {
            'user info loaded';
            eventBus.emit('changeActivity', {
              activity: 'RegisterActivity',
              open: true,
              data: {
                sso: 'infoconnect',
                ssoToken: this.infoconnect.keycloak.token,
                ssoEmail: info.email,
              },
              redirectUrl: window.urls.adCreate,
            })
          })
        }
      });
    },
    initialize() {
      return Infoconnect.initialize().then((infoconnect) => {
        this.initialized = true;
        this.infoconnect = infoconnect;
      });
    },
  },
  beforeMount() {
    this.initialize().then(() => {
    });
  }
}
</script>