<template>
  <form
      ref="form"
      class="needs-validation no-loader d-flex flex-vertical flex-grow-1"
      :class="{'was-validated': formValidated}"
      novalidate
  >
    <Tabs active="register"/>
    <div class="side-panel-content side-panel-padding" :class="{'pt-10': step == 1}">
      <div v-if="step == 1" class="p-3 bg-skyblue" style="border: solid 1px;" v-html="message"/>
      <div class="font-h2 text-center my-5">
        <p>{{ stepTitle }}</p>
      </div>
      <div data-step="1" v-show="step == 1">
        <div class="form-group mt-5">
          <label class="form-label">{{ translate('front.user_panel.register_pro.civility_label') }}&nbsp;*</label>
          <div class="my-1" :class="{'is-invalid': (formValidated && !civility) || civilityErrors.length > 0 }">
            <div class="form-check form-check-inline">
              <input class="form-check-input" :class="{'from-sso': ssoCivility && ssoCivility == civility}" type="radio"
                     name="civility" id="input-civility-m" required="required" value="monsieur" v-model="civility" data-test="login-panel-registration-civility-m-input">
              <label class="form-label"
                     :class="{'from-sso': ssoCivility && ssoCivility == civility}"
                     for="input-civility-m">{{ translate('front.user_panel.register_pro.civility_male') }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" :class="{'from-sso': ssoCivility && ssoCivility == civility}" type="radio"
                     name="civility" id="input-civility-f" required="required" value="madame" v-model="civility" data-test="login-panel-registration-civility-f-input">
              <label class="form-label"
                     :class="{'from-sso': ssoCivility && ssoCivility == civility}"
                     for="input-civility-f">{{ translate('front.user_panel.register_pro.civility_female') }}</label>
            </div>
          </div>
          <div class="invalid-feedback">
            <span v-if="civilityErrors.length == 0">{{
                translate('front.user_panel.register_part.civility_error')
              }}</span>
            <span v-else v-for="error in civilityErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5">
          <label for="input-lastname"
                 class="form-label">{{ translate('front.user_panel.register_pro.lastname_label') }}&nbsp;*</label>
          <input
              type="text"
              name="lastname"
              id="input-lastname"
              class="form-control"
              maxlength="35"
              :class="{
            'is-invalid': lastnameErrors.length > 0,
            'from-sso': ssoLastname && ssoLastname == lastname,
          }"
              required="required"
              v-model="lastname"
              data-test="login-panel-registration-lastname-input"
          />
          <div class="invalid-feedback">
            <span v-if="lastnameErrors.length == 0">{{
                translate('front.user_panel.register_pro.lastname_error')
              }}</span>
            <span v-else v-for="error in lastnameErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5">
          <label for="input-firstname"
                 class="form-label">{{ translate('front.user_panel.register_pro.firstname_label') }}&nbsp;*</label>
          <input
              type="text"
              name="firstname"
              id="input-firstname"
              class="form-control"
              maxlength="35"
              :class="{
            'is-invalid': firstnameErrors.length > 0,
            'from-sso': ssoLastname && ssoLastname == lastname,
          }"
              required="required"
              v-model="firstname"
              data-test="login-panel-registration-firstname-input"
          />
          <div class="invalid-feedback">
            <span v-if="firstnameErrors.length == 0">{{
                translate('front.user_panel.register_pro.firstname_error')
              }}</span>
            <span v-else v-for="error in firstnameErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5" v-if="!sso">
          <label for="input-email"
                 class="form-label">{{ translate('front.user_panel.register_pro.email_label') }}&nbsp;*</label>
          <input
              type="email"
              name="email"
              id="input-email"
              class="form-control"
              :class="{'is-invalid': emailErrors.length > 0 }"
              :disabled="lockEmail"
              required="required"
              v-model="email"
              data-test="login-panel-registration-email-input"
          />
          <div class="invalid-feedback">
            <span v-if="emailErrors.length == 0">{{ translate('front.user_panel.register_pro.email_error') }}</span>
            <span v-else v-for="error in emailErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5" v-if="!sso">
          <PhoneInput
              name="phone"
              :required="true"
              :formValidated="formValidated"
              :label="translate('front.user_panel.register_pro.phone_label') + ' *'"
              :class="{'is-invalid': phoneErrors.length > 0 }"
              :error-messages="phoneErrors.length > 0 ? phoneErrors : [translate('front.user_panel.register_pro.phone_error')]"
              v-model="phone"
              data-test="login-panel-registration-phone-input"
          />
        </div>

      </div>
      <div class="" data-step="2" v-show="step == 2">
        <div class="form-group my-5">
          <label for="input-officeName"
                 class="form-label">{{ translate('front.user_panel.register_pro.office_name_label') }}&nbsp;*</label>
          <input
              type="text"
              name="officeName"
              id="input-officeName"
              class="form-control"
              :class="{'is-invalid': officeNameErrors.length > 0 }"
              required="required"
              v-model="officeName"
              data-test="login-panel-registration-office-name"
          />
          <div class="invalid-feedback">
            <span v-if="officeNameErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_name_error')
              }}</span>
            <span v-else v-for="error in officeNameErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5">
          <label for="input-officeAddress"
                 class="form-label">{{ translate('front.user_panel.register_pro.office_address_label') }}&nbsp;*</label>
          <input
              type="text"
              name="officeAddress"
              ref="officeAddress"
              id="input-officeAddress"
              class="form-control"
              :class="{'is-invalid': officeAddressErrors.length > 0 }"
              required="required"
              :disabled="officeAddressCustom"
              v-model="officeAddress"
              data-test="login-panel-registration-office-location"
          />
          <div class="invalid-feedback">
            <span v-if="officeAddressErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_address_error')
              }}</span>
            <span v-else v-for="error in officeAddressErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5">
          <input type="checkbox" name="cgu" id="input-officeAddressCustom" class="form-check-input"
                 v-model="officeAddressCustom"/>
          <label for="input-officeAddressCustom" class="form-label d-inline text-black">{{
              translate('front.user_panel.register_pro.office_address_custom_label')
            }}</label>
        </div>
        <div class="form-group my-5" v-if="officeAddressCustom">
          <label for="input-officeAddressAddress" class="form-label">{{
              translate('front.user_panel.register_pro.office_address_address_label')
            }}&nbsp;*</label>
          <input
              type="text"
              name="officeAddressAddress"
              id="input-officeAddressAddress"
              class="form-control"
              :class="{'is-invalid': officeAddressAddressErrors.length > 0 }"
              required="required"
              v-model="officeAddressAddress"
          />
          <div class="invalid-feedback">
            <span v-if="officeAddressAddressErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_address_address_error')
              }}</span>
            <span v-else v-for="error in officeAddressAddressErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5" v-if="officeAddressCustom">
          <label for="input-officeAddressComplement" class="form-label">{{
              translate('front.user_panel.register_pro.office_address_complement_label')
            }}</label>
          <input
              type="text"
              name="officeAddressComplement"
              id="input-officeAddressComplement"
              class="form-control"
              :class="{'is-invalid': officeAddressComplementErrors.length > 0 }"
              v-model="officeAddressComplement"
          />
          <div class="invalid-feedback">
            <span v-if="officeAddressComplementErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_address_complement_error')
              }}</span>
            <span v-else v-for="error in officeAddressComplementErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5" v-if="officeAddressCustom">
          <label for="input-officeAddressZipcode" class="form-label">{{
              translate('front.user_panel.register_pro.office_address_zipcode_label')
            }}&nbsp;*</label>
          <input
              type="text"
              name="officeAddressZipcode"
              id="input-officeAddressZipcode"
              class="form-control"
              :class="{'is-invalid': officeAddressZipcodeErrors.length > 0 }"
              required="required"
              v-model="officeAddressZipcode"
          />
          <div class="invalid-feedback">
            <span v-if="officeAddressZipcodeErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_address_zipcode_error')
              }}</span>
            <span v-else v-for="error in officeAddressZipcodeErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5" v-if="officeAddressCustom">
          <label for="input-officeAddressCity" class="form-label">{{
              translate('front.user_panel.register_pro.office_address_city_label')
            }}&nbsp;*</label>
          <input
              type="text"
              name="officeAddressCity"
              id="input-officeAddressCity"
              class="form-control"
              :class="{'is-invalid': officeAddressCityErrors.length > 0 }"
              required="required"
              v-model="officeAddressCity"
          />
          <div class="invalid-feedback">
            <span v-if="officeAddressCityErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_address_city_error')
              }}</span>
            <span v-else v-for="error in officeAddressCityErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5" v-if="officeAddressCustom">
          <label for="input-officeAddressCountry" class="form-label">{{
              translate('front.user_panel.register_pro.office_address_country_label')
            }}&nbsp;*</label>
          <select
              name="officeAddressCountry"
              id="input-officeAddressCountry"
              class="form-select"
              :class="{'is-invalid': officeAddressCountryErrors.length > 0 }"
              required="required"
              v-model="officeAddressCountry"
          >
            <option v-for="countryOption in countries" :value="countryOption.value">{{ countryOption.name }}</option>
          </select>
          <div class="invalid-feedback">
            <span v-if="officeAddressCountryErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_address_country_error')
              }}</span>
            <span v-else v-for="error in officeAddressCountryErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5">
          <label for="input-officeEmail"
                 class="form-label">{{ translate('front.user_panel.register_pro.office_email_label') }}&nbsp;*</label>
          <input
              type="email"
              name="officeEmail"
              id="input-officeEmail"
              class="form-control"
              :class="{'is-invalid': officeEmailErrors.length > 0 }"
              required="required"
              v-model="officeEmail"
              data-test="login-panel-registration-office-email"
          />
          <div class="invalid-feedback">
            <span v-if="officeEmailErrors.length == 0">{{
                translate('front.user_panel.register_pro.office_email_error')
              }}</span>
            <span v-else v-for="error in officeEmailErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5">
          <PhoneInput
              name="office"
              :required="true"
              :formValidated="formValidated"
              :label="translate('front.user_panel.register_pro.office_phone_label') + ' *'"
              :class="{'is-invalid': officePhoneErrors.length > 0 }"
              :error-messages="officePhoneErrors.length > 0 ? officePhoneErrors : [translate('front.user_panel.register_pro.office_phone_error')]"
              v-model="officePhone"
              data-test="login-panel-registration-office-phone"
          />
        </div>

        <div class="form-group my-5">
          <label for="input-vat" class="form-label">{{
              translate('front.user_panel.register_pro.office_vat_label')
            }}</label>
          <input
              type="text"
              name="vat"
              id="input-vat"
              class="form-control"
              :class="{'is-invalid': officeVatErrors.length > 0 }"
              v-model="officeVat"
          />
          <div class="invalid-feedback" v-if="officeVatErrors.length > 0">
            <span v-for="error in officeVatErrors">{{ error }}</span>
          </div>
        </div>
      </div>
      <div class="" data-step="3" v-show="step == 3">
        <div class="form-group my-5" v-if="!sso">
          <PasswordStrengthIndicator
              v-model="password"
              @strengthChange="onStrengthChange"
              :formValidated="formValidated"
              :invalidFeedback="passwordErrors.length > 0 ? passwordErrors[0].message : translate('front.user_panel.register_pro.password_error')"
          />
        </div>
        <div class="form-group my-5" v-if="sso">
          <label for="input-email-sso"
                 class="form-label">{{ translate('front.user_panel.register_pro.email_label') }}&nbsp;*</label>
          <input
              type="email"
              name="email"
              id="input-email-sso"
              class="form-control"
              :class="{
            'is-invalid': emailErrors.length > 0,
            'from-sso': ssoLastname && ssoLastname == lastname,
          }"
              required="required"
              v-model="email"
          />
          <div class="invalid-feedback">
            <span v-if="emailErrors.length == 0">{{ translate('front.user_panel.register_pro.email_error') }}</span>
            <span v-else v-for="error in emailErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group my-5" v-if="sso">
          <PhoneInput
              name="sso"
              :required="true"
              :formValidated="formValidated"
              :label="translate('front.user_panel.register_pro.phone_label') + ' *'"
              :class="{'is-invalid': phoneErrors.length > 0 }"
              :error-messages="phoneErrors.length > 0 ? phoneErrors : [translate('front.user_panel.register_pro.phone_error')]"
              v-model="phone"
          />
        </div>
        <div class="form-group my-5">
          <input type="checkbox" name="cgu" id="input-cgu" class="form-check-input" required="required" v-model="cgu" data-test="login-panel-registration-terms" />
          <label for="input-cgu" class="form-label d-inline text-black">{{
              translate('front.user_panel.register_pro.data_agreement')
            }} *</label>
          <div class="invalid-feedback">{{ translate('front.user_panel.register_pro.agreement_error') }}</div>
        </div>
      </div>

      <div class="d-flex flex-vertical mt-5 mb-n5">
        <div class="d-flex flex-horizontal justify-content-between">
          <a href="#" class="btn border-primary bg-white text-primary"
             @click.prevent.stop="onPrevious">{{
              translate('front.user_panel.register_pro.previous_button_label')
            }}</a>
          <input
              type="submit"
              class="btn btn-primary"
              :value="translate('front.user_panel.register_pro.next_button_label')"
              :disabled="submitDisabled"
              @click.prevent.stop="onSubmit"
              data-test="login-panel-registration-submit"
          />
        </div>
        <Stepper
            class="mt-4"
            :nb-steps="4"
            :current-step="step"
            :previous="previousStep"
            :next="nextStep"
            :type="'text'"
        />
      </div>
    </div>
    <div class="side-panel-footer side-panel-footer-adaptive">
      <Stepper
          class="position-absolute bottom-0 w-100 left-0"
          :nb-steps="4"
          :current-step="step"
          :previous="previousStep"
          :next="nextStep"
          :type="'progressbar'"
      />
    </div>

  </form>
</template>

<script>
import Stepper from '../components/Stepper';
import PasswordStrengthIndicator from '../components/PasswordStrengthIndicator'
import Tabs from '../components/Tabs';
import PhoneInput from "../../vue/components/PhoneInput";
import {GoogleMap} from '../../google-map';
import {getAddress, getAddressComplement, getZipcode, getCity, getCountry} from '../../addressable';
import translatable from '../../vue/mixins/translatable';
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "RegisterFormPro",
  components: {
    Stepper,
    PasswordStrengthIndicator,
    Tabs,
    PhoneInput,
  },
  mixins: [translatable],
  props: [
    'sso',
    'ssoToken',
    'ssoEmail',
    'ssoLastname',
    'ssoFirstname',
    'ssoCivility',
    'data',
    'redirectUrl',
    'lockEmail',
  ],
  data() {
    return {
      formValidated: false,
      sending: false,
      civility: this.data.civility ?? this.ssoCivility ?? null,
      lastname: this.data.lastname ?? this.ssoLastname ?? null,
      firstname: this.data.firstname ?? this.ssoFirstname ?? null,
      email: this.data.email ?? this.ssoEmail ?? null,
      phone: null,
      officeName: null,
      officeAddress: null,
      officeAddressCustom: false,
      officeAddressAddress: null,
      officeAddressComplement: null,
      officeAddressCoordsX: null,
      officeAddressCoordsY: null,
      officeAddressZipcode: null,
      officeAddressName: null,
      officeAddressCity: null,
      officeAddressCountry: document.getElementsByTagName('html')[0].lang == 'en' ? 'GB' : document.getElementsByTagName('html')[0].lang.toUpperCase(),
      countries: [],
      officeEmail: null,
      officePhone: null,
      officeVat: null,
      password: null,
      cgu: false,
      errors: [],
      step: 1,
      passwordStrength: 0,
      steps: [
        'Général',
        'Votre agence',
        'Mot de passe',
        'Validation',
      ],
      placeAutocomplete: null,
    };
  },
  computed: {
    message() {
      return this.translate('front.user_panel.register_pro.message')
    },
    stepTitle() {
      if (this.sso && this.hasTranslation(`front.user_panel.register_pro.step${this.step}-sso_title`)) {
        return this.translate(`front.user_panel.register_pro.step${this.step}-sso_title`)
      }
      return this.translate(`front.user_panel.register_pro.step${this.step}_title`)
    },
    previousStep() {
      if (this.step > 1) {
        return this.getStepperTitle(this.step - 1);
      }
    },
    nextStep() {
      return this.getStepperTitle(this.step + 1);
    },
    civilityErrors() {
      return this.errors.filter(error => error.path == 'civility').map(error => error.message);
    },
    lastnameErrors() {
      return this.errors.filter(error => error.path == 'lastname').map(error => error.message);
    },
    firstnameErrors() {
      return this.errors.filter(error => error.path == 'firstname').map(error => error.message);
    },
    emailErrors() {
      return this.errors.filter(error => error.path == 'email').map(error => error.message);
    },
    phoneErrors() {
      return this.errors.filter(error => error.path == 'phone').map(error => error.message);
    },
    passwordErrors() {
      return this.errors.filter(error => error.path == 'password').map(error => error.message);
    },
    officeNameErrors() {
      return this.errors.filter(error => error.path == 'officeName').map(error => error.message);
    },
    officeAddressErrors() {
      return this.errors.filter(error => error.path == 'officeAddress').map(error => error.message);
    },
    officeEmailErrors() {
      return this.errors.filter(error => ['officeEmail', 'contactEmail'].includes(error.path)).map(error => error.message);
    },
    officePhoneErrors() {
      return this.errors.filter(error => ['officePhone', 'contactPhone'].includes(error.path)).map(error => error.message);
    },
    officeVatErrors() {
      return this.errors.filter(error => error.path == 'officeVat').map(error => error.message);
    },
    officeAddressAddressErrors() {
      return this.errors.filter(error => error.path == 'officeAddressAddress').map(error => error.message);
    },
    officeAddressComplementErrors() {
      return this.errors.filter(error => error.path == 'officeAddressComplement').map(error => error.message);
    },
    officeAddressZipcodeErrors() {
      return this.errors.filter(error => error.path == 'officeAddressZipcode').map(error => error.message);
    },
    officeAddressCityErrors() {
      return this.errors.filter(error => error.path == 'officeAddressCity').map(error => error.message);
    },
    officeAddressCountryErrors() {
      return this.errors.filter(error => error.path == 'officeAddressCountry').map(error => error.message);
    },
    step1Errors() {
      return this.civilityErrors.length > 0
          || this.lastnameErrors.length > 0
          || this.firstnameErrors.length > 0
          || (this.emailErrors.length > 0 && !this.sso)
          || (this.phoneErrors.length > 0 && !this.sso);
    },
    step2Errors() {
      return this.officeNameErrors.length > 0
          || this.officeAddressErrors.length > 0
          || this.officeEmailErrors.length > 0
          || this.officePhoneErrors.length > 0
          || this.officeVatErrors.length > 0;
    },
    submitDisabled() {
      return this.sending
        || (
          this.step == 3
          && !this.sso
          && this.passwordStrength < 4
        );
    },
  },
  watch: {
    civility(newValue, oldValue) {
      this.removeErrors('civility');
    },
    lastname(newValue, oldValue) {
      this.removeErrors('lastname');
    },
    firstname(newValue, oldValue) {
      this.removeErrors('firstname');
    },
    email(newValue, oldValue) {
      this.removeErrors('email');
    },
    phone(newValue, oldValue) {
      this.removeErrors('phone');
    },
    password(newValue, oldValue) {
      this.removeErrors('password');
    },
    officeName(newValue, oldValue) {
      this.removeErrors('officeName');
    },
    officeAddress(newValue, oldValue) {
      this.removeErrors('officeAddress');
    },
    officeEmail(newValue, oldValue) {
      this.removeErrors('officeEmail');
    },
    officePhone(newValue, oldValue) {
      this.removeErrors('officePhone');
    },
  },
  methods: {
    getStepperTitle(step) {
      if (this.sso && this.hasTranslation(`front.user_panel.register_pro.step${step}-sso.stepper_title`)) {
        return this.translate(`front.user_panel.register_pro.step${step}-sso.stepper_title`);
      }
      return this.translate(`front.user_panel.register_pro.step${step}.stepper_title`);
    },
    onSubmit() {
      this.formValidated = true;
      this.$refs.form.checkValidity();

      if (
          this.$refs.form.querySelectorAll(`[data-step="${this.step}"] :invalid`).length > 0 || this.submitDisabled
      ) {
        return;
      }

      datalayerPush('click-login-registration', {
        user_type: 'pro',
        step: this.step,
      });

      switch (this.step) {
        case 1:
          if (!this.sso) {
            this.checkEmail();
          } else {
            this.formValidated = false;
            this.gotToStep(this.step + 1);
          }
          break;
        case 2:
          this.formValidated = false;
          this.gotToStep(this.step + 1);
          break;
        case 3:
          this.submit();
          break;
      }
    },
    checkEmail() {
      if (this.email.includes('+')) {
        this.formValidated = false;
        this.errors = [{
          path: 'email',
          message: this.translate('front.user_panel.register_part.email_error'),
        }];
        return;
      }
      fetch(window.urls.checkEmail, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: this.email}),
      }).then((response) => {
        response.json().then((result) => {
          this.formValidated = false;
          if (result.success) {
            this.gotToStep(this.step + 1);
          } else {
            this.removeErrors('email');
            this.errors.push({
              path: 'email',
              message: result.message,
            });
            this.$nextTick(() => {
              this.$refs.form.querySelector('input[type=email]').focus();
            });
            datalayerPush('error', {
              'errorType': 'registrationFailed',
              'errorField': 'email',
              'errorMessage': result.message,
            });
          }
        })
      })
    },
    checkPhone() {
      if (!this.phoneHasValue) {
        this.errors.push({
          path: 'phone',
          message: this.translate('front.user_panel.register_pro.phone_error'),
        });
      }
    },
    checkOfficePhone() {
      if (!this.officePhoneHasValue) {
        this.errors.push({
          path: 'officePhone',
          message: this.translate('front.user_panel.register_pro.office_phone_error'),
        });
      }
    },
    submit() {
      if (this.sending) {
        return;
      }
      this.sending = true;
      let data = {
        civility: this.civility,
        lastname: this.lastname,
        firstname: this.firstname,
        email: this.email,
        phone: this.phone,
        office: {
          name: this.officeName,
          contactEmail: this.officeEmail,
          contactPhone: this.officePhone,
          vatNumber: this.officeVat,
          addressable: {
            address: this.officeAddressAddress,
            addressComplement: this.officeAddressComplement,
            zipcode: this.officeAddressZipcode,
            city: this.officeAddressCity,
            country: this.officeAddressCountry,
          },
          geolocation: {
            x: this.officeAddressCoordsX,
            y: this.officeAddressCoordsY,
          },
          company: {
            name: this.officeName,
          },
        },
      };
      if (this.sso) {
        data.sso = this.sso;
        data.ssoToken = this.ssoToken;
      } else {
        data.password = this.password;
      }
      if (this.redirectUrl) {
        data.redirectUrl = this.redirectUrl;
      }
      fetch(window.urls.registerPro, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((response) => {
        this.formValidated = false;
        this.sending = false;
        response.json().then((result) => {
          if (Math.floor(response.status / 100) === 2 && result.success) {
            eventBus.emit('registerSuccess');
            eventBus.emit('changeActivity', {
              activity: 'ConfirmActivity',
              data: {
                email: this.email,
                phone: this.phone,
                steps: 4,
                previousStep: this.getStepperTitle(this.step),
                isPro: true,
              },
            });
          } else {
            this.errors = result.errors ?? [];
            if (this.step1Errors) {
              this.gotToStep(1);
            } else if (this.step2Errors) {
              this.gotToStep(2);
            }
            result.errors.forEach((error) => {
              datalayerPush('error', {
                'errorType': 'registrationFailed',
                'errorField': error.path,
                'errorMessage': error.message,
              });
            });
          }
        });
      });
    },
    removeErrors(path = null) {
      if (path) {
        this.errors = this.errors.filter(error => error.path != path);
      } else {
        this.errors = [];
      }
    },
    onStrengthChange(event) {
      this.passwordStrength = event.strength;
    },
    onPrevious() {
      if (this.step > 1) {
        this.gotToStep(this.step - 1);
      } else {
        this.$emit('changeScreen', {
          screen: 'RegisterTypeSelectionScreen',
          data: {
            civility: this.civility,
            lastname: this.lastname,
            firstname: this.firstname,
            email: this.email,
          },
        });
      }
    },
    onPlaceChanged(event) {
      let place = this.placeAutocomplete.getPlace();

      this.officeAddressName = place.name;
      this.officeAddressAddress = getAddress(place.address_components, place);
      this.officeAddressComplement = getAddressComplement(place.address_components);
      this.officeAddressZipcode = getZipcode(place.address_components);
      this.officeAddressCity = getCity(place.address_components);
      this.officeAddressCountry = getCountry(place.address_components);
      this.officeAddressCoordsX = place.geometry.location.lng();
      this.officeAddressCoordsY = place.geometry.location.lat();

      this.officeAddress = this.$refs.officeAddress.value
    },
    gotToStep(step) {
      datalayerPush('vpv-login-registration', {
        userType: 'pro',
        step: step,
      });

      this.step = step;
      this.$nextTick(() => {
        this.$refs.form.querySelector('input').focus();
      });
    },
  },
  mounted() {
    this.$refs.form.querySelector('input').focus();

    GoogleMap.loadGoogleMapsApi(['places']).then((googleMaps) => {
      this.placeAutocomplete = new googleMaps.places.Autocomplete(this.$refs.officeAddress);
      googleMaps.event.addListener(
          this.placeAutocomplete,
          'place_changed',
          this.onPlaceChanged,
      )
    });

    fetch(window.urls.listCountries, {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then((response) => {
      response.json().then((result) => {
        this.countries = result;
      });
    })

    datalayerPush('vpv-login-registration', {
      userType: 'pro',
      step: 1,
    });
  },
}
</script>
