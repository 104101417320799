<template>
  <component
      :is="currentScreen"
      :email="email"
      :userPhone="phone"
      :steps="steps"
      :previousStep="previousStep"
      :redirectUrl="redirectUrl"
      :isPro="isPro"
      :emailOnly="emailOnly"
      @changeScreen="onChangeScreen"
      @validationSuccess="onValidationSuccess"
  />
</template>

<script>
import ConfirmEmailForm from "../screen/ConfirmEmailForm";
import ConfirmPhoneForm from "../screen/ConfirmPhoneForm";
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "ConfirmActivity",
  inheritAttrs: false,
  components: {
    ConfirmEmailForm,
    ConfirmPhoneForm,
  },
  props: [
    'email',
    'phone',
    'steps',
    'previousStep',
    'redirectUrl',
    'isPro',
    'isLead',
  ],
  data() {
    return {
      currentScreen: 'ConfirmEmailForm',
      emailOnly: this.userData?.lockEmail ?? this.isLead ?? false,
    }
  },
  methods: {
    changeScreen(screen) {
      this.currentScreen = screen;
    },
    onChangeScreen(event) {
      this.changeScreen(event.screen)
    },
    onValidationSuccess(event) {
      this.$emit('setCanClose', {
        canClose: true,
      });
      eventBus.emit('connectionSuccess', {
        onboarding: true,
        close: false,
      });
    },
    dataLayerVpvPush(isPro) {
      datalayerPush('vpv-login-registration',{
        userType: isPro ? 'pro' : 'individual',
        step: isPro ? 4 : 3,
      });
    }
  },
  mounted() {
    eventBus.emit('setCanClose', {
      canClose: false,
    });

    if (typeof this.isPro === 'undefined') {
      fetch(window.urls.accountStatus, {
        headers: { Accept: 'application/json' },
      }).then((response) => {
        response.json().then((result) => {
          this.isPro = result.officeId !== null;
          this.dataLayerVpvPush(this.isPro);
        })
      })
    } else {
      this.dataLayerVpvPush(this.isPro);
    }
  }
}
</script>
