<template>
  <form
      ref="form"
      @submit.prevent="onSubmit"
      class="needs-validation no-loader d-flex flex-vertical flex-grow-1"
      :class="{'was-validated': formValidated}"
      novalidate
  >
    <Tabs active="register"/>
    <div class="side-panel-content side-panel-padding" :style="stepTitle ? '' : 'padding-top: 1rem'">
      <div v-if="stepTitle" class="font-h2 text-center my-5">
        <p>{{ stepTitle }}</p>
      </div>
      <div v-if="step == 1">
        <div class="form-group mt-5">
          <label class="form-label">{{ translate('front.user_panel.register_part.civility_label') }}&nbsp;*</label>
          <div class="mb-1 mt-2" :class="{'is-invalid': (formValidated && !civility) || civilityErrors.length > 0 }">
            <div class="form-check form-check-inline">
              <input class="form-check-input" :class="{'from-sso': ssoCivility && ssoCivility == civility}" type="radio"
                     name="civility" id="input-civility-m" required="required" value="monsieur" v-model="civility" data-test="login-panel-registration-civility-m-input">
              <label class="form-label"
                     :class="{'from-sso': ssoCivility && ssoCivility == civility}"
                     for="input-civility-m">{{ translate('front.user_panel.register_part.civility_male') }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" :class="{'from-sso': ssoCivility && ssoCivility == civility}" type="radio"
                     name="civility" id="input-civility-f" required="required" value="madame" v-model="civility" data-test="login-panel-registration-civility-f-input">
              <label class="form-label"
                     :class="{'from-sso': ssoCivility && ssoCivility == civility}"
                     for="input-civility-f">{{ translate('front.user_panel.register_part.civility_female') }}</label>
            </div>
          </div>
          <div class="invalid-feedback">
            <span v-if="civilityErrors.length == 0">{{
                translate('front.user_panel.register_part.civility_error')
              }}</span>
            <span v-else v-for="error in civilityErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5">
          <label for="input-lastname"
                 class="form-label">{{ translate('front.user_panel.register_part.lastname_label') }}&nbsp;*</label>
          <input
              type="text"
              name="lastname"
              id="input-lastname"
              class="form-control"
              maxlength="35"
              :class="{
              'is-invalid': lastnameErrors.length > 0,
              'from-sso': ssoLastname && ssoLastname == lastname,
            }"
              required="required"
              v-model="lastname"
              data-test="login-panel-registration-lastname-input"
          />
          <div class="invalid-feedback">
            <span v-if="lastnameErrors.length == 0">{{
                translate('front.user_panel.register_part.lastname_error')
              }}</span>
            <span v-else v-for="error in lastnameErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5">
          <label for="input-firstname"
                 class="form-label">{{ translate('front.user_panel.register_part.firstname_label') }}&nbsp;*</label>
          <input
              type="text"
              name="firstname"
              id="input-firstname"
              class="form-control"
              maxlength="35"
              :class="{
              'is-invalid': firstnameErrors.length > 0,
              'from-sso': ssoFirstname && ssoFirstname == firstname,
            }"
              required="required"
              v-model="firstname"
              data-test="login-panel-registration-firstname-input"
          />
          <div class="invalid-feedback">
            <span v-if="firstnameErrors.length == 0">{{
                translate('front.user_panel.register_part.firstname_error')
              }}</span>
            <span v-else v-for="error in firstnameErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-group mt-5" v-if="!sso">
          <label for="input-email"
                 class="form-label">{{ translate('front.user_panel.register_part.email_label') }}&nbsp;*</label>
          <input
              type="email"
              name="email"
              id="input-email"
              class="form-control"
              :class="{'is-invalid': emailErrors.length > 0}"
              :disabled="lockEmail"
              required="required"
              v-model="email"
              data-test="login-panel-registration-email-input"
          />
          <div class="invalid-feedback">
            <span v-if="emailErrors.length == 0">{{ translate('front.user_panel.register_part.email_error') }}</span>
            <span v-else v-for="(error, index) in emailErrors"><br v-if="index > 0" />{{ error }}</span>
          </div>
        </div>
      </div>
      <div v-else-if="step == 2">
        <div class="form-group mt-5" v-if="!sso">
          <PasswordStrengthIndicator
              v-model="password"
              @strengthChange="onStrengthChange"
              :formValidated="formValidated"
              :invalidFeedback="passwordErrors.length > 0 ? passwordErrors[0].message : translate('front.user_panel.register_part.password_error')"
          />
        </div>
        <div class="form-group mt-5" v-if="sso">
          <label for="input-email-sso"
                 class="form-label">{{ translate('front.user_panel.register_part.email_label') }}&nbsp;*</label>
          <input
              type="email"
              name="email"
              id="input-email-sso"
              class="form-control"
              :class="{
                'is-invalid': emailErrors.length > 0,
                'from-sso': ssoEmail && ssoEmail == email,
              }"
              required="required"
              v-model="email"
          />
          <div class="invalid-feedback">
            <span v-if="emailErrors.length == 0">{{ translate('front.user_panel.register_part.email_error') }}</span>
            <span v-else v-for="error in emailErrors">{{ error }}</span>
          </div>
        </div>
        <div class="form-check mt-5">
          <input type="checkbox" name="newsletter" id="input-newsletter" class="form-check-input" v-model="newsletter"/>
          <label for="input-newsletter" class="form-label d-inline text-black">{{
              translate('front.user_panel.register_part.newsletter_label')
            }}</label>
        </div>
        <div class="form-check mt-5">
          <input type="checkbox" name="cgu" id="input-cgu" class="form-check-input" required="required" v-model="cgu" data-test="login-panel-registration-terms" />
          <label for="input-cgu" class="form-label d-inline text-black">{{
              translate('front.user_panel.register_part.personal_data_agreement')
            }} *</label>
          <div class="invalid-feedback">{{ translate('front.user_panel.register_part.agreement_error') }}</div>
        </div>
      </div>

      <div class="d-flex flex-vertical mt-5 mb-n5">
        <div class="d-flex flex-horizontal justify-content-between">
          <a href="#" class="btn border-primary bg-white text-primary"
             @click.prevent="onPrevious">{{ translate('front.user_panel.register_part.previous_button_label') }}</a>
          <input type="submit" class="btn btn-primary"
                 data-test="login-panel-registration-submit"
                 :value="translate('front.user_panel.register_part.next_button_label')" :disabled="submitDisabled"/>
        </div>

        <Stepper
            class="mt-4"
            :nb-steps="3"
            :current-step="step"
            :previous="previousStep"
            :next="nextStep"
            :type="'text'"
        />
      </div>
    </div>

    <div class="side-panel-footer side-panel-footer-adaptive">
      <Stepper
          class="position-absolute bottom-0 w-100 left-0"
          :nb-steps="3"
          :current-step="step"
          :previous="previousStep"
          :next="nextStep"
          :type="'progressbar'"
      />
    </div>

  </form>
</template>

<script>
import Stepper from '../components/Stepper';
import PasswordStrengthIndicator from '../components/PasswordStrengthIndicator';
import Tabs from '../components/Tabs';
import translatable from '../../vue/mixins/translatable';
import eventBus from '../eventBus'
import {datalayerPush} from "../../lib/datalayer";

export default {
  name: "RegisterFormPart",
  components: {
    Stepper,
    PasswordStrengthIndicator,
    Tabs,
  },
  mixins: [translatable],
  props: [
    'sso',
    'ssoToken',
    'ssoEmail',
    'ssoLastname',
    'ssoFirstname',
    'ssoCivility',
    'data',
    'redirectUrl',
    'lockEmail',
  ],
  data() {
    return {
      formValidated: false,
      sending: false,
      civility: this.data.civility ?? this.ssoCivility ?? null,
      lastname: this.data.lastname ?? this.ssoLastname ?? null,
      firstname: this.data.firstname ?? this.ssoFirstname ?? null,
      email: this.data.email ?? this.ssoEmail ?? null,
      password: null,
      cgu: false,
      newsletter: false,
      errors: [],
      step: 1,
      passwordStrength: 0,
    };
  },
  computed: {
    stepTitle() {
      if (1 === this.step) {
        return ''
      }
      if (this.sso && this.hasTranslation(`front.user_panel.register_part.step${this.step}-sso_title`)) {
        return this.translate(`front.user_panel.register_part.step${this.step}-sso_title`)
      }
      return this.translate(`front.user_panel.register_part.step${this.step}_title`)
    },
    previousStep() {
      if (this.step > 1) {
        return this.getStepperTitle(this.step - 1);
      }
    },
    nextStep() {
      return this.getStepperTitle(this.step + 1);
    },
    civilityErrors() {
      return this.errors.filter(error => error.path == 'civility').map(error => error.message);
    },
    lastnameErrors() {
      return this.errors.filter(error => error.path == 'lastname').map(error => error.message);
    },
    firstnameErrors() {
      return this.errors.filter(error => error.path == 'firstname').map(error => error.message);
    },
    emailErrors() {
      return this.errors.filter(error => error.path == 'email').map(error => error.message);
    },
    passwordErrors() {
      return this.errors.filter(error => error.path == 'password').map(error => error.message);
    },
    step1Errors() {
      return this.civilityErrors.length > 0
          || this.lastnameErrors.length > 0
          || this.firstnameErrors.length > 0
          || (!this.sso && this.emailErrors.length > 0);
    },
    submitDisabled() {
      return this.sending
          || (
              this.step == 2
              && !this.sso
              && this.passwordStrength < 4
          );
    },
  },
  watch: {
    civility(newValue, oldValue) {
      this.removeErrors('civility');
    },
    lastname(newValue, oldValue) {
      this.removeErrors('lastname');
    },
    firstname(newValue, oldValue) {
      this.removeErrors('firstname');
    },
    email(newValue, oldValue) {
      this.removeErrors('email');
    },
    password(newValue, oldValue) {
      this.removeErrors('password');
    },
  },
  methods: {
    getStepperTitle(step) {
      if (this.sso && this.hasTranslation(`front.user_panel.register_part.step${step}-sso.stepper_title`)) {
        return this.translate(`front.user_panel.register_part.step${step}-sso.stepper_title`);
      }
      return this.translate(`front.user_panel.register_part.step${step}.stepper_title`);
    },
    onSubmit() {
      this.formValidated = true;
      if (!this.$refs.form.checkValidity() || this.submitDisabled) {
        return;
      }
      datalayerPush('click-login-registration', {
        user_type: 'individual',
        step: this.step,
      });

      switch (this.step) {
        case 1:
          this.checkEmail();
          break;
        case 2:
          this.submit();
          break;
      }
    },
    checkEmail() {
      if (this.email.includes('+')) {
        this.formValidated = false;
        this.errors = [{
          path: 'email',
          message: this.translate('front.user_panel.register_part.email_error'),
        }];
        return;
      }
      fetch(window.urls.checkEmail, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: this.email}),
      }).then((response) => {
        response.json().then((result) => {
          this.formValidated = false;
          if (result.success) {
            this.gotToStep(2);
          } else {
            this.errors = [{
              path: 'email',
              message: result.message,
            }];
            this.$nextTick(() => {
              this.$refs.form.querySelector('input[type=email]').focus();
            });
            datalayerPush('error', {
              'errorType': 'registrationFailed',
              'errorField': 'email',
              'errorMessage': result.message,
            });
          }
        })
      })
    },
    submit() {
      if (this.sending) {
        return;
      }
      this.sending = true;
      let data = {
        civility: this.civility,
        lastname: this.lastname,
        firstname: this.firstname,
        email: this.email,
        newsletterBandOfBoats: this.newsletter,
      };
      if (this.sso) {
        data.sso = this.sso;
        data.ssoToken = this.ssoToken;
      } else {
        data.password = this.password;
      }
      if (this.redirectUrl) {
        data.redirectUrl = this.redirectUrl;
      }
      fetch(window.urls.registerIndividual, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((response) => {
        this.formValidated = false;
        this.sending = false;
        response.json().then((result) => {
          if (Math.floor(response.status / 100) === 2 && result.success) {
            sendinblue.track(
              "registration",
              {
                "email": this.email,
                "PRENOM": this.firstname,
                "NOM": this.lastname,
                "EMAIL_VERIFIE": result.email_confirmed ? 'Oui' : 'Non',
                ...result.main_language ? {"LOCALE": result.main_language} : {},
              },
              {
                "data": {
                  "environment": result.env,
                  "email_validation_url": result.confirmation_url,
                },
              },
            );
            eventBus.emit('registerSuccess');
            eventBus.emit('changeActivity', {
              activity: 'ConfirmActivity',
              data: {
                email: this.email,
                steps: 3,
                previousStep: this.getStepperTitle(this.step),
                isPro: false,
              },
            });
          } else {
            this.errors = result.errors;
            if (this.step1Errors) {
              this.step = 1;
            }

            result.errors.forEach((error) => {
              datalayerPush('error', {
                'errorType': 'registrationFailed',
                'errorField': error.path,
                'errorMessage': error.message,
              });
            });
          }
        });
      });
    },
    removeErrors(path = null) {
      if (path) {
        this.errors = this.errors.filter(error => error.path != path);
      } else {
        this.errors = [];
      }
    },
    onStrengthChange(event) {
      this.passwordStrength = event.strength;
    },
    onPrevious() {
      if (this.step > 1) {
        this.gotToStep(this.step - 1);
      } else {
        this.$emit('changeScreen', {
          screen: 'RegisterTypeSelectionScreen',
          data: {
            civility: this.civility,
            lastname: this.lastname,
            firstname: this.firstname,
            email: this.email,
          },
        });
      }
    },
    gotToStep(step) {
      datalayerPush('vpv-login-registration', {
        userType: 'individual',
        step: step,
      });

      this.step = step;
      this.$nextTick(() => {
        this.$refs.form.querySelector('input').focus();
      });
    },
  },
  mounted() {
    this.$refs.form.querySelector('input').focus();

    datalayerPush('vpv-login-registration', {
      userType: 'individual',
      step: 1,
    });
  },
}
</script>
