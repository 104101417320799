<template>
  <HomeScreen
    :login-message="loginMessage"
  />
</template>

<script>
import HomeScreen from "../screen/HomeScreen";

export default {
  name: "HomeActivity",
  inheritAttrs: false,
  components: {HomeScreen},
  props: {
    loginMessage: {
      type: String,
      required: false,
    },
  },
}
</script>
